import { InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import classes from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "app/store";


interface Props {
    changeBlockType: (event: React.ChangeEvent<HTMLInputElement>) => void,
    blockType: "classic" | "TABATA" | "EMOM" | "AMRAP" | "FORTIME",
}



export default function BlockTypeButton({changeBlockType, blockType}:Props){

    const performed = useSelector((state:RootState) => state.workouts.builder.performed)
    


    return(
        <TextField
            select
            size="small"
            onChange={changeBlockType}
            value={blockType ? blockType : "classic"}
            disabled={Boolean(performed)}
            sx={[
                classes.prescriptionTextField, 
            ]}
            InputProps={{
                startAdornment: <InputAdornment position="start" sx={{color:"rgba(0,0,0,0.85)", fontWeight: 600}} disableTypography>
                    Type : 
                </InputAdornment>
        
            }}
            
        
        >
            <MenuItem
                value="classic"
            >
                Classique
            </MenuItem>
            <MenuItem
                value="TABATA"
                disabled
            >
                Tabata (bientôt disponible)
            </MenuItem>
            <MenuItem
                value="EMOM"
                disabled

            >
                EMOM (bientôt disponible)
            </MenuItem>
            <MenuItem
                value="AMRAP"
                disabled

            >
                AMRAP (bientôt disponible)
            </MenuItem>
            <MenuItem
                value="FORTIME"
                disabled

            >
                For time (bientôt disponible)
            </MenuItem>
        </TextField>
    )
}