import { Circuit, GroupExercice} from "interfaces/Workout";
import { caloriesMetric, durationMetric, kilometersMetric, metersMetric, repsMetric } from "utils/constants";
import convertFormatedTimeToSeconds from "./convertFormatedTimeToSeconds";
import { ExerciceMetrics } from "interfaces/Exercice";



const generateSecondaryPrescription = (prescription:any, metric:ExerciceMetrics, isFirstPrescription: boolean, isLastPrescription:boolean) => {

    var formated = ""
    if(isFirstPrescription && prescription !== null){
        formated = " ("
    }

    switch (metric.label.fr){
        case "RPE" : {
            formated = formated + `RPE : ${prescription}`
            break;
        }

        case "Répétitions" : {
            formated = formated + `${prescription} reps`
            break;
        }

        case "Distance (kilomètres)" : {
            formated = formated + `${prescription} km`
            break;
        }

        case "Distance (mètres)" : {
            formated = formated + `${prescription} m`
            break;
        }

        case "%RM" : {
            formated = formated + `${prescription} %RM`
            break;
        }

        case "Watts" : {
            formated = formated + `${prescription} watts`
            break;
        }

        case "FC" : {
            formated = formated + `${prescription}% de la FC`
            break;
        }

        case "Poids" : {
            formated = formated + `${prescription} kg`
            break;
        }

        case "RM" : {
            formated = formated + `${prescription} RM`
            break;
        }

        case "Calories" : {
            formated = formated + `${prescription} cal`
            break;
        }

        case "Calories" : {
            formated = formated + `${prescription}% de la VMA`
            break;
        }

        case "Résistance" : {
            formated = formated + `Résistance : ${prescription}`
            break;
        }

        case "Réserve de reps" : {
            formated = formated + `RIR : ${prescription}`
            break;
        }

        case "Leste" : {
            formated = formated + `${prescription} kg lestés`
            break;
        }

        case "Tempos" : {
            formated = formated + `Tempos : ${prescription}`
            break;
        }

        case "PMA" : {
            formated = formated + `${prescription}% de la PMA`
            break;
        }
        case "VMA" : {
            formated = formated + `${prescription}% de la VMA`
            break;
        }
    }

    
    if(isLastPrescription && prescription !== null){
        formated = formated + ")"
    }

    return formated
}




export default function generateWodDescription(blockType:"classic" | "AMRAP" | "EMOM" | "TABATA" | "FORTIME", content:GroupExercice[], circuit:Circuit){

    var generated = ""


    if(blockType === "TABATA"){
        generated = generated + `Réalise ${circuit.rounds} fois le circuit ci-dessous en alternant ${convertFormatedTimeToSeconds(circuit.work)}s d'effort et ${convertFormatedTimeToSeconds(circuit.rest)}s de repos.</br></br>`
    
    }

    if(blockType === "FORTIME"){
        
        if(circuit.rounds > 1){
            generated = generated + `Réalise ${circuit.rounds} fois le circuit ci-dessous le plus rapidement possible. </br></br>`
        } else {
            generated = generated + `Réalise le circuit ci-dessous le plus rapidement possible. </br></br>`
        }
    
    }

    if(blockType === "AMRAP"){
        var generated = "As Many Rounds As Possible </br>"
        generated = generated + `Répète le circuit ci-dessous pendant ${circuit.work}min et compte le nombre de tours que tu as effectué. </br></br>`
    
    }


    if(blockType === "EMOM"){
        var generated = "Every Minute on the Minute </br>"
        generated = generated + "Réalise chaque exercice du circuit ci-dessous en moins d'une minute. Le temps restant sur cette minute sera ton temps de repos. </br></br>"
    }


    content.forEach((elem)=> {

        generated = generated + `- `
        
        elem.exercices.forEach((exercice, exerciceIndex)=> {

            /***********************
                TABATA GENERATION
            ************************/

            if(blockType === "TABATA"){
                const durationMetricIndex = exercice.metrics.findIndex((metric)=> metric._id === durationMetric)
                generated = generated + `<strong>${convertFormatedTimeToSeconds(circuit.work)}s ${exercice.exercice.name.fr}</strong>`
                exercice.sets.forEach((set) => {
                    const filteredPrescriptions=  set.prescriptions.filter((_,setIndex) => {
                        if(Boolean(durationMetricIndex !== -1)){
                            return setIndex !== durationMetricIndex
                        } else {
                            return true
                        }
                        
                    })
                    const filteredMetrics=  exercice.metrics.filter((_,metricIndex) => {
                        if(Boolean(durationMetricIndex !== -1)){
                            return metricIndex !== durationMetricIndex
                        } else {
                            return true
                        }
                    })


                    filteredPrescriptions.forEach((prescription,prescriptionIndex)=> {
                        const metric = filteredMetrics[prescriptionIndex]
                        const isFirstPrescription = prescriptionIndex === 0
                        const isLastPrescription = prescriptionIndex === filteredPrescriptions.length -1
                       

                        generated = generated + generateSecondaryPrescription(prescription, metric, isFirstPrescription,isLastPrescription)

                        if(prescriptionIndex < filteredPrescriptions.length - 1){
                            generated = generated + " - "
                        }
                    })
                })


                generated = generated + `</br>- ${convertFormatedTimeToSeconds(circuit.rest)}s Repos`
            }
            
            /***********************
                EMOM GENERATION
            ************************/
            else {
                const durationMetricIndex = exercice.metrics.findIndex((metric)=> metric._id === durationMetric)
                const repsMetricIndex = exercice.metrics.findIndex((metric)=> metric._id === repsMetric)
                const metersMetricIndex = exercice.metrics.findIndex((metric)=> metric._id === metersMetric)
                const caloriesMetricIndex = exercice.metrics.findIndex((metric)=> metric._id === caloriesMetric)
                const kilometersMetricIndex = exercice.metrics.findIndex((metric)=> metric._id === kilometersMetric)
                // Circuit ?
                const evolution = exercice.circuit?.evolution
                const evolutionValue = exercice.circuit?.value

                var primaryPrescription:number = null

                // Si duration est défini, priorité 1 (uniquement en AMRAP et FORTIME)
                if(durationMetricIndex !== -1 && (blockType === "AMRAP" || blockType === "FORTIME")){
                    primaryPrescription = durationMetricIndex
                    exercice.sets.forEach((set)=> {
                        const prescription = set.prescriptions[durationMetricIndex]
                        generated = generated + `<strong>${prescription}`
                        generated = generated + ` `
                        generated = generated + `${exercice.exercice.name.fr}</strong>`
                        if(evolution === "decrement"){
                            generated = generated + ` (- ${evolutionValue} s à chaque tour) `
                        }
                        if(evolution === "increment"){
                            generated = generated + ` (+ ${evolutionValue} s à chaque tour) `

                        }
                    })
                }

                // Si reps est défini, priorité 2
                else if(repsMetricIndex !== -1){
                    primaryPrescription = repsMetricIndex
                    exercice.sets.forEach((set)=> {
                        const prescription = set.prescriptions[repsMetricIndex]
                        generated = generated + `<strong>${prescription}`
                        generated = generated + ` x `
                        generated = generated + `${exercice.exercice.name.fr}</strong>`
                        if(evolution === "decrement"){
                            generated = generated + ` (- ${evolutionValue} rep${evolutionValue >1 ? "s" : ""} à chaque tour) `
                        }
                        if(evolution === "increment"){
                            generated = generated + ` (+ ${evolutionValue} rep${evolutionValue >1 ? "s" : ""} à chaque tour) `

                        }
                    })
                } 
                // Si Mètres est défini, priorité 3
                else if(metersMetricIndex !== -1){
                    primaryPrescription = metersMetricIndex

                    exercice.sets.forEach((set)=> {
                        generated = generated + `<strong>${set.prescriptions[metersMetricIndex]} m `
                        generated = generated + `${exercice.exercice.name.fr}</strong>`

                        if(evolution === "decrement"){
                            generated = generated + ` (- ${evolutionValue}m à chaque tour) `
                        }
                        if(evolution === "increment"){
                            generated = generated + ` (+ ${evolutionValue}m à chaque tour) `

                        }
                    })    
                } 

                // Si kilomètres est défini, priorité 4
                else if(kilometersMetricIndex !== -1){
                    primaryPrescription = kilometersMetricIndex

                    exercice.sets.forEach((set)=> {
                        generated = generated + `<strong>${set.prescriptions[kilometersMetricIndex]} km `
                        generated = generated + `${exercice.exercice.name.fr}</strong>`

                        if(evolution === "decrement"){
                            generated = generated + ` (- ${evolutionValue}km à chaque tour) `
                        }
                        if(evolution === "increment"){
                            generated = generated + ` (+ ${evolutionValue}km à chaque tour) `

                        }
                    })    
                } 
                // Si calories est défini, priorité 5
                else if(caloriesMetricIndex !== -1){
                    primaryPrescription = caloriesMetricIndex

                    exercice.sets.forEach((set)=> {
                        generated = generated + `<strong>${set.prescriptions[caloriesMetricIndex]} cals `
                        generated = generated + `${exercice.exercice.name.fr}</strong>`

                        if(evolution === "decrement"){
                            generated = generated + ` (- ${evolutionValue} cals à chaque tour) `
                        }
                        if(evolution === "increment"){
                            generated = generated + ` (+ ${evolutionValue} cals à chaque tour) `

                        }
                    })    
                }
                
                // Si rien n'est défini, on affiche "1 min"
                else if(blockType === "EMOM"){
                    generated = generated + `<strong>1 min de ${exercice.exercice.name.fr}</strong>`
                } 

                // Si le nombre de metrics est supérieur à 0
                // On affiche le reste des metrics qui n'ont pas été affichés avant'
                console.log('metrics', exercice.metrics)
                if(exercice.metrics.length> 1){
                    
                    exercice.sets.forEach((set) => {
                        const filteredPrescriptions=  set.prescriptions.filter((_,setIndex) => {
                            if(primaryPrescription !== -1){
                                return setIndex !== primaryPrescription && durationMetricIndex !== setIndex
                            } else {
                                return true
                            }
                            
                        })

                        const filteredMetrics=  exercice.metrics.filter((_,metricIndex) => {
                            if(primaryPrescription !== -1){
                                return metricIndex !== primaryPrescription && durationMetricIndex !== metricIndex
                            } else {
                                return true
                            }
                        })


                        filteredPrescriptions.forEach((prescription,prescriptionIndex)=> {
                            const metric = filteredMetrics[prescriptionIndex]
                            const isFirstPrescription = prescriptionIndex === 0
                            const isLastPrescription = prescriptionIndex === filteredPrescriptions.length -1
                         
                            generated = generated + generateSecondaryPrescription(prescription, metric, isFirstPrescription, isLastPrescription)
                            
                        })
                    })
                
                }
            }

            

            if(exerciceIndex < elem.exercices.length -1){
                generated = generated + ` + `
            }
        })

        generated = generated + `</br>`

    })


    return generated
}