import React, { useState } from "react"
// MUI
import { Card, Box, RadioGroup, FormControlLabel, IconButton, Button, Typography, useMediaQuery, Menu, MenuItem, ListItemIcon, ListItemText} from "@mui/material";
import { Close, Delete, Notes, RadioButtonChecked, CheckBox, CheckBoxOutlineBlankOutlined, RadioButtonUnchecked, Add, SvgIconComponent, MoreHoriz, Flaky} from "@mui/icons-material";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// REDUCERS
import { deleteQuestion, removeOption, handleQuestionType, pushOption} from "features/checkupSlice";
// Interfaces
import { AppDispatch, RootState } from "app/store";
import { CheckupQuestion as CheckupQuestionType } from "interfaces/Checkup";
// STYLES
import classes from "./styles";
import { createSelector } from "@reduxjs/toolkit";
import QuestionTextField from "components/molecules/CheckupQuestionTextField";
import OptionTextField from "components/molecules/CheckupOptionTextField";
import { MOBILE, TABLET } from "utils/constants";


/******** INTERFACES ******* */

interface Props{
    questionId: string,
    questionIndex: number
}



/*********** FUNCTIONS ********** */

function getQuestion (checkupBuilder:CheckupQuestionType[], questionId:string){
    const question:CheckupQuestionType = checkupBuilder.find((elem:CheckupQuestionType)=> elem.questionId === questionId)
    return question
}



/****** REDUX SELECTORS ***** */

const questionSelector = createSelector(
    [
        (state:RootState)=> state.checkups,
        (_,questionId) => questionId,
    ],
    (checkupBuilder, questionId) => {
        const checkupQuestion:CheckupQuestionType = getQuestion(checkupBuilder.builder.questions, questionId)
        return checkupQuestion.question
    }
)


const optionsSelector = createSelector(
    [
        (state:RootState)=> state.checkups,
        (_,questionId) => questionId,
    ],
    (checkupBuilder, questionId) => {
        const checkupQuestion:CheckupQuestionType = getQuestion(checkupBuilder.builder.questions, questionId)
        return checkupQuestion.options
    }
)

const responseTypeSelector = createSelector(
    [
        (state:RootState)=> state.checkups,
        (_,questionId) => questionId,
    ],
    (checkupBuilder, questionId) => {
        const checkupQuestion:CheckupQuestionType = getQuestion(checkupBuilder.builder.questions, questionId)
        return checkupQuestion.type
    }
)


function QuestionType ({label,Icon, action}:{label: string, Icon: SvgIconComponent, action: (e:any)=>void}){
    return(
        <Box
            onClick={action}
            sx={classes.questionType}
        >
            <Box
                sx={classes.questionIcon}
            >
                <Icon/>
            </Box>
          
            <Typography
                sx={{fontWeight: 600}}
            >
                {label}
            </Typography>
        </Box>
    )
}


  /******** MAIN COMPONENT ******* */

export function Question({questionId,questionIndex}:Props){

    // Gestion du nom du checkup
    const dispatch = useDispatch<AppDispatch>()
    const options:string[] = useSelector((state:RootState)=> optionsSelector(state,questionId))
    const responseType:string = useSelector((state:RootState)=> responseTypeSelector(state,questionId))
    const question:string = useSelector((state:RootState)=> questionSelector(state,questionId))
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const [anchorMore, setAnchorMore] = useState<any>(null)

    /****** CHANGEMENT DU TYPE DE QUESTION ******* */

    const onChangeType = (questionType:"comment" | "radio" | 'checkbox' | "photos" | "yesOrNo") => ()=>{
        dispatch(handleQuestionType({questionIndex, questionType}))
        setAnchorEl(null)
    }

    /************** AJOUT d'UNE OPTION ********* */

    const onAddOption = () => {
        dispatch(pushOption({questionIndex }))
    }

    
    /************** SUPPRESSION d'UNE OPTION ********* */

    const onRemoveOption = (optionIndex:number) => ()=>{
        dispatch(removeOption({questionIndex, optionIndex }))
    }

    const onDeleteQuestion = () => {
        dispatch(deleteQuestion({questionId}))
        setAnchorMore(null)
    }

    const onOpenMenu = (e:any)=> {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        
    }


    const onCloseMenu = (e:any) => {
        e.stopPropagation()
        setAnchorEl(null)
        
    } 


    return(
   
            <Box sx={classes.card}>

                {/******** HEADER ******** */}
                <Box sx={classes.header}>

                    <Box
                        sx={{display:"flex", flexDirection:"column", width: "100%"}}
                    >
                         {/************************************** 
                         *  SELECTION DU TYPE DE QUESTION ***** 
                         **************************************/}

                        <Box
                            sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center",  marginBottom: 2}}
                        >
                            {responseType === "comment" && (
                                <QuestionType 
                                    Icon={Notes}
                                    label={"Réponse libre"}
                                    action={onOpenMenu}
                                />
                            )}
                    
                            {responseType === "radio" && (
                                <QuestionType 
                                    Icon={RadioButtonChecked}
                                    label={"Choix unique"}
                                    action={onOpenMenu}
                                />
                            
                            )}

                            {responseType === "checkbox" && (
                                <QuestionType 
                                    Icon={CheckBox}
                                    label={"Choix multiple"}
                                    action={onOpenMenu}
                                />
                            )}

                            {/*responseType === "photos" && (
                                <QuestionType 
                                    Icon={CheckBox}
                                    label={"Photos"}
                                    action={onOpenMenu}
                                />
                            )*/}
                            
                

                            <Menu disableScrollLock={true}  
                                open={Boolean(anchorEl)}
                                onClose={onCloseMenu}
                                anchorEl={anchorEl}>
                                <MenuItem onClick={onChangeType("comment")}>
                                    <ListItemIcon>
                                        <Notes />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Réponse libre
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={onChangeType("checkbox")}>
                                    <ListItemIcon>
                                        <CheckBox />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Choix multiple
                                    </ListItemText>
                                </MenuItem>
                              <MenuItem onClick={onChangeType("radio")}>
                                    <ListItemIcon>
                                        <RadioButtonChecked />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Choix unique
                                    </ListItemText>
                                </MenuItem>
                                {/*}
                                <MenuItem onClick={onChangeType("yesOrNo")}>
                                    <ListItemIcon>
                                        <Flaky />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Oui/Non
                                    </ListItemText>
                                </MenuItem>
                                {/*<MenuItem onClick={onChangeType("scale10")}>
                                    <ListItemIcon>
                                        <Flaky />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Échelle 1 à 10
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={onChangeType("scale10")}>
                                    <ListItemIcon>
                                        <Flaky />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Échelle 1 à 5
                                    </ListItemText>
                                </MenuItem>
                                */}
                                {/*}
                                <MenuItem onClick={onChangeType("photos")}>
                                    <ListItemIcon>
                                        <RadioButtonChecked />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Photos d'évolution
                                    </ListItemText>
                                </MenuItem>
                                */}
                            </Menu>


                            <IconButton
                                onClick={(e) => setAnchorMore(e.currentTarget)}
                            >
                                <MoreHoriz
                                    sx={{color: "rgba(0,0,0,0.85)"}}
                                />
                            </IconButton>

                            <Menu 
                                    disableScrollLock={true}  
                                    open={Boolean(anchorMore)}
                                    onClose={()=> setAnchorMore(null)}
                                    anchorEl={anchorMore}
                                >
                                    <MenuItem 
                                        onClick={onDeleteQuestion}
                                    >
                                        <ListItemIcon>
                                            <Delete />
                                        </ListItemIcon>
                                        <ListItemText>
                                           Supprimer la question
                                        </ListItemText>
                                    </MenuItem>
                                </Menu>

                        </Box>
                   

                        <Box sx={classes.questionContainer}>
                            {/*** QUESTION ***** */}
                            <QuestionTextField 
                                question={question}
                                questionIndex={questionIndex}
                            />
                    
                        </Box>

                    </Box>


                    {/*** BUTTON DELETE ***** 
                    <Box sx={classes.deleteButton}>
                        <IconButton onClick={onDeleteQuestion}>
                            <Delete />
                        </IconButton>
                    </Box>
                    */}
                </Box>

                

                    {/******** CHAMP DE TEXTE ******** */}

                    {responseType === "comment" && (
                        <Box sx={classes.fakeTextArea}>
                            Réponse libre de ton client
                        </Box>
                    )}
                                
                    {/******** RADIO ******** */}

                    {responseType === "radio" && (
                        options.map((label:string, index: number)=> (
                            <Box 
                                sx={classes.option} 
                                key={index}
                            >
                                <RadioButtonUnchecked 
                                    sx={{mr: 3, padding: 0, ml:1, color: "#aaaaaa"}}
                                />

                                <OptionTextField
                                    optionIndex={index}
                                    optionLabel={label}
                                    questionIndex={questionIndex}
                                />

                                <IconButton sx={{ml: 3}} onClick={onRemoveOption(index)}>
                                    <Close />
                                </IconButton>
                            </Box>
                        ))
                    )}

                    {/******** CHECKBOX ******** */}

                    {responseType === "checkbox" && (
                        options.map((label:string, index: number)=> (
                            <Box sx={classes.option} key={index}>
                                <CheckBoxOutlineBlankOutlined 
                                    //size="small" 
                                    //disabled 
                                    sx={{mr: 3, padding: 0, ml:1, color: "#aaaaaa"}}
                                />

                                <OptionTextField
                                    optionIndex={index}
                                    optionLabel={label}
                                    questionIndex={questionIndex}
                                />

                                <IconButton 
                                    sx={{ml: 3}} 
                                    onClick={onRemoveOption(index)}
                                >
                                    <Close />
                                </IconButton>
                            </Box>
                        ))
                    )}


                    {/******** BOUTON AJOUT D'UNE OPTION ******** */}

                    {(responseType === "checkbox" || responseType === "radio") &&(
                        <Button sx={{padding: 0.1, paddingTop: 1, paddingBottom: 1}} onClick={onAddOption}>
                 
                                <Add
                                sx={{mr: 3, ml: 1, padding: 0}}/>
                            
                            Ajouter une option de réponse
                        </Button>
                    )}
        
            </Box>
       
    )
}


function areEqual(prev:Props, next:Props){
    return prev.questionIndex === next.questionIndex && prev.questionId === next.questionId
}

export default React.memo(Question, areEqual)