import { AddLink, Delete, LinkOff, MoreHoriz, ShowChart, SwapHoriz, Timer, TimerOff } from "@mui/icons-material"
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery } from "@mui/material"
import { AppDispatch, RootState } from "app/store";
import DialogClientPerformances from "components/organisms/DialogClientPerformances";
import { handleExerciceRest, mergeGroups, removeExerciceFromWorkout } from "features/workoutSlice";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectExerciceHasRest, selectBlockType, selectExerciceIsFromSuperset, selectIsLastExerciceInGroup, selectGroupIsLastOfBloc } from "selectors/workoutSelectors";
import { MOBILE } from "utils/constants";
import classes from "./styles";
import { DialogExerciceDelete } from "../DialogExerciceDelete";

interface Props {
    blockId: string,
    groupId: string,
    exerciceId: string,
    onOpenSwap: (exerciceId:string, groupId:string)=> void
}

export default function WorkoutExerciceMoreButton({blockId, groupId, exerciceId, onOpenSwap}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const hasRest = useSelector((state:RootState)=> selectExerciceHasRest(state,groupId, exerciceId, blockId))
    const isLastGroupExercice:boolean = useSelector((state:RootState)=> selectIsLastExerciceInGroup(state,groupId, exerciceId, blockId))
    const blockType = useSelector((state:RootState)=> selectBlockType(state, blockId))
    const fromSuperset = useSelector((state:RootState)=> selectExerciceIsFromSuperset(state, groupId, blockId))
    const groupIsLastOfBlock = useSelector((state:RootState)=> selectGroupIsLastOfBloc(state, groupId, blockId))
    const [openPerformances, setOpenPerformances] = useState<boolean>(false)
    const [openExerciceDelete, setOpenExerciceDelete] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)


    const isTabata = useMemo(()=>{
        return Boolean(blockType) && blockType === "TABATA"
    },[blockType])

    const isEMOM = useMemo(()=>{
        return Boolean(blockType) && blockType === "EMOM"
    },[blockType])

    const isAMRAP = useMemo(()=>{
        return Boolean(blockType) && blockType === "AMRAP"
    },[blockType])

    const isClassic = useMemo(()=>{
        return !Boolean(blockType) || blockType === "classic"
    },[blockType])



    /*********************************************
     * Ouvre le dialog de remplacement d'exercice
     * *******************************************/ 
    const onCreateGroup = () => {
        dispatch(mergeGroups({blockId, groupId}))
        setAnchorEl(null)
    }


    /*********************************************
     * Ouvre le dialog de remplacement d'exercice
     * *******************************************/ 
    const onOpenSwapDialog = () =>{
        onOpenSwap(exerciceId, groupId)
        setAnchorEl(null)

    }


    /***********************************
     * Dégroupe un exercice d'un superset
     * **********************************/ 
    const onRemove = () => {
        dispatch(removeExerciceFromWorkout({
            blockId, 
            exerciceId, 
            groupId
        }))
        setAnchorEl(null)
    }

    /***********************************
     * Supprime un exercice du workout
     * **********************************/ 
    const onDelete = () => {
        setOpenExerciceDelete(true)
        setAnchorEl(null)
    }


    
    const onHandleRest = () => {
        dispatch(handleExerciceRest({
            blockId,
            groupId,
            exerciceId, 
            rest: !hasRest
        }))
        setAnchorEl(null)
    }


    /***********************************
     * Ouvre le dialog des performances
     * **********************************/ 
    const onOpenExercicePerformances = () => {
        setAnchorEl(null)
        setOpenPerformances(true)
    }



    return(
        <>
        {!mobile && (
            <IconButton 
                sx={classes.exerciceButton} 
                onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
            >
                <MoreHoriz />
            </IconButton>
        )}


        <Menu 
                disableScrollLock={true} 
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={()=>setAnchorEl(null)}
            >

                
                <MenuItem 
                    onClick={onOpenExercicePerformances}
                >
                    <ListItemIcon>
                        <ShowChart fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Performances du client
                    </ListItemText>
                </MenuItem>



                {((isClassic || isEMOM) && (!groupIsLastOfBlock && isLastGroupExercice)) && (
                    <MenuItem 
                        onClick={onCreateGroup} 
                        disabled={!isClassic && !isEMOM}
                    >
                        <ListItemIcon>
                            <AddLink />
                        </ListItemIcon>
                        <ListItemText>
                            {isEMOM ? "Grouper" : "Enchaîner (Superset)"}
                        </ListItemText>
                    </MenuItem>
                
                )}

                {fromSuperset && (
                    <MenuItem onClick={onRemove}>
                        <ListItemIcon>
                            <LinkOff />
                        </ListItemIcon>
                        <ListItemText>
                            Dégrouper du superset
                        </ListItemText>
                    </MenuItem>

                )}

                <MenuItem onClick={onOpenSwapDialog}>
                    <ListItemIcon>
                    <SwapHoriz fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Remplacer
                    </ListItemText>
                </MenuItem>

            
            
                {!fromSuperset && (
                    <MenuItem 
                        onClick={onDelete}
                    >
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Supprimer
                        </ListItemText>
                    </MenuItem>
                )}

                {mobile && (
                    <MenuItem 
                        onClick={onHandleRest} 
                        disabled={!isLastGroupExercice || (isTabata || isEMOM || isAMRAP)}
                    >
                        <ListItemIcon>
                            {hasRest ? <TimerOff /> : <Timer/>}
                        </ListItemIcon>
                        <ListItemText>
                            {hasRest ? "Supprimer les temps de repos": "Ajouter des temps de repos"}
                        </ListItemText>
                    </MenuItem>
                )}
                
        </Menu>


        <DialogClientPerformances 
            open={openPerformances}
            onClose={()=> setOpenPerformances(false)}
            exerciceId={exerciceId}
            groupId={groupId}
            blockId={blockId}
        />

        <DialogExerciceDelete 
            open={openExerciceDelete}
            onClose={()=>setOpenExerciceDelete(false)}
            exerciceId={exerciceId}
            blockId={blockId}
            groupId={groupId}
        />
        </>
    )
}