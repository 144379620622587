import { createSlice } from "@reduxjs/toolkit";
import { deleteQuestionnaire, getPlanning } from "api/clients";
import { deleteCustomCover, uploadCover } from "api/cover";
import { createNewProgram, deleteProgram, getProgram, importProgram, updateProgram, updateProgramDuration } from "api/programs";
import { createWorkout, deleteMultipleWorkouts, deleteWorkout, getWorkouts, pasteMultiplesWorkouts, pasteWorkout, updateMultipleWorkoutsStatus, updateWorkout } from "api/workouts";
import { addDays, differenceInCalendarDays, endOfMonth, endOfWeek, format, isBefore, isWithinInterval, startOfMonth, startOfWeek } from "date-fns";
import { fr } from "date-fns/locale";
import Checkup from "interfaces/Checkup";
import Planning from "interfaces/Planning";
import Program from "interfaces/Program";
import Workout from "interfaces/Workout";
import { toast } from "react-toastify";


interface PlanningSlice {
    builder:Planning,
    user: {
      _id: string,
      lastname:string,
      firstname:string,
      avatar: {
        url : string,
      },
      
    },
    requests:{
        update: "idle" | "pending", // Update du program template
        getPlanning: "idle" | "pending",
        getWorkouts: "idle" | "pending",
        pasteWorkout: "idle" | "pending",
        pasteMultipleWorkouts: "idle" | "pending",
        updateMultipleWorkouts: "idle" | "pending",
        deleteMultipleWorkouts: "idle" | "pending",
        createProgram: "idle" | "pending",
        updateProgram: "idle" | "pending",
        deleteProgram : "idle" | "pending",
        importProgram: "idle" | "pending",
        uploadCover: "idle" | "pending",
        deleteCover: "idle" | "pending",
        deleteQuestionnaire: "idle" | "pending"
      },
      fixDaysLabel: boolean
}

const initialState = {
    builder:{
        _id: undefined,
        name: "",
        startDate:startOfWeek(new Date(startOfMonth(new Date())), {locale: fr}).toDateString(),
        endDate:endOfWeek(new Date(endOfMonth(new Date())), {locale: fr}).toDateString(),
        isReady: false,
        workouts: [],
        checkups: [],
        programs: [],
        workoutCopied: null,
        checkupCopied: null,
        weekCopied: null,
        
      },
      user: null,
      fixDaysLabel: false,
      requests:{
        update: "idle",
        getPlanning: "idle",
        getWorkouts: "idle",
        pasteWorkout: "idle",
        pasteCheckup: "idle",
        pasteMultipleWorkouts: "idle",
        updateMultipleWorkouts: "idle",
        deleteMultipleWorkouts: "idle",
        createProgram: "idle",
        updateProgram: "idle",
        deleteProgram : "idle",
        importProgram: "idle",
        uploadCover: "idle",
        deleteCover: "idle",
        deleteQuestionnaire: "idle"
      },
} as PlanningSlice



export const planningSlice = createSlice({
    name: 'planning',
    initialState,
    reducers: {

        resetWorkoutCopied: (state) => {
          state.builder.workoutCopied = null
        },

        resetPlanning: (state) => {
            state.builder ={
              _id: undefined,
              name: "",
              startDate: startOfWeek(new Date(startOfMonth(new Date())), {locale: fr}).toDateString(),
              endDate: endOfWeek(new Date(endOfMonth(new Date())), {locale: fr}).toDateString(),
              isReady: false,
              workouts: [],
              checkups:[],
              programs:[],
              workoutCopied: state.builder.workoutCopied,
              checkupCopied: state.builder.checkupCopied,
              weekCopied: state.builder.weekCopied,
              template: false

            }
            state.user = null
        },


        programIsSaving: (state) => {
          state.requests.update = "pending"
        },


        handleProgramTemplateName: (state, action:{payload:{name:string}}) => {
          state.builder.name = action.payload.name
        },

        handleProgramTemplateDescription: (state, action:{payload:{description:string}}) => {
          state.builder.description = action.payload.description
        },


        defineNewPlanningRange :(state,action) => {
            const start:string = action.payload.start
            const end:string = action.payload.end
      
            state.builder.startDate = start
            state.builder.endDate = end
        },

        copyWorkoutPlanning:(state,action) => {
            const workout:Workout = {...action.payload.workout, results: undefined}
            state.builder.workoutCopied = workout
        },

        copyWeekPlanning: (state,action) => {
          
            var workouts:Workout[] = action.payload.workouts
            var currentWeekStart:Date = action.payload.weekStart
            var currentWeekEnd:Date = action.payload.weekEnd
            const isTemplate = action.payload.isTemplate

            var weekCopied:{weekStart:Date, weekEnd: Date, workouts:Workout[], fromTemplate:boolean} = {
              weekStart: currentWeekStart,
              weekEnd: currentWeekEnd, 
              workouts,
              fromTemplate: true
            }
            
            /*
            console.log('Séances de base')
            console.log("Début de semaine :", format(new Date(currentWeekStart), "eeee dd MMMM yyyy HH:mm", {locale:fr}))
            console.log("FIN de semaine :", format(new Date(currentWeekEnd), "eeee dd MMMM yyyy HH:mm", {locale:fr}))
            console.log('------- LISTE DES SEANCES A COPIER --------')
            weekCopied.workouts.forEach((elem)=> {
              console.log('Séance le :', format(new Date(elem.schedule), "eeee dd MMMM yyyy HH:mm", {locale:fr}))
            })
              */


           if (isTemplate) {

             
              // Si template, on décale les jours vers le "vrai" début de semaine, pas celui du template
              const newWeekStart = startOfWeek(new Date(currentWeekStart), { locale: fr });
              const newWeekEnd = endOfWeek(newWeekStart, { locale: fr });

              const differenceInDays = differenceInCalendarDays(new Date(newWeekStart), new Date(currentWeekStart));
       
            
              const updatedWorkouts = workouts.map((workout) => ({
                ...workout, // Copie l'objet pour éviter l'erreur "lecture seule"
                schedule: addDays(new Date(workout.schedule), differenceInDays).toString(),
              }));

              weekCopied = {
                weekStart: newWeekStart,
                weekEnd: newWeekEnd, 
                workouts: updatedWorkouts,
                fromTemplate: true
              }
            }
            state.builder.weekCopied = weekCopied


            
        },

        // CHECKUPS
        copyCheckupPlanning:(state,action) => {
            const checkup:Checkup = action.payload.checkup
            state.builder.checkupCopied = checkup
        },

        handleDaysLabelPosition:(state,action:{payload:{positionFixed:boolean}}) => {
          const positionFixed:boolean = action.payload.positionFixed
            if(positionFixed !== state.fixDaysLabel){
              state.fixDaysLabel = positionFixed
            }
        }
    },

    extraReducers(builder) {
    builder

    /********* RECUPERATION DU PLANNING ****** */

    .addCase(getPlanning.pending,(state) =>{
        state.requests.getPlanning = "pending"
    })

    .addCase(getPlanning.fulfilled,(state,{payload}) =>{
        state.requests.getPlanning = "idle"
        const {planning, user} = payload
        if(planning){
            const {
              workouts, 
              checkups, 
              programs
            } = planning
            state.builder = {
              ...state.builder,
              workouts,
              checkups,
              programs,
              isReady: true,
              template: false,
            }
            state.user = user
        } else{
            toast.error('Impossible de récupérer le planning pour le mois en cours')
        }
    })

    .addCase(getPlanning.rejected,(state, action) =>{
        toast.error('Impossible de récupérer les données du planning')
    })


    /******** CREATE WORKOUT ******* */

    .addCase(createWorkout.fulfilled,(state,{payload})=>{
        const {workout} = payload
        if(workout && (!workout.template || workout.programTemplate)){ // Séance personnalisée
            state.builder.workouts.push(workout)
           
        }
    })

    .addCase(getWorkouts.pending,(state)=>{
      state.requests.getWorkouts = "pending"
    })

    .addCase(getWorkouts.fulfilled,(state)=>{
      state.requests.getWorkouts = "idle"
    })
  
  
    .addCase(getWorkouts.rejected,(state)=>{
        state.requests.getWorkouts = "idle"
        toast.error('Impossible de récupérer la liste des séances')
    })
  
      
      /******** MISE A JOUR WORKOUT ******* */
      .addCase(updateWorkout.fulfilled, (state,{payload}) => {
        if(payload.workout){
        const workout:Workout = {...payload.workout, createdAt: payload.workout.createdAt}
        if(!workout?.template || workout.programTemplate){ // workout custom
            const index = state.builder.workouts.findIndex((elem:Workout)=> elem._id === workout._id)
            state.builder.workouts[index] = workout
        } 
        }
      })
  
  
      /******** DELETE WORKOUT ******* */
      .addCase(deleteWorkout.fulfilled, (state,{payload}) => {
        const workout:Workout = payload.workout
        if(workout && (!workout.template || workout.programTemplate)){ // exercice custom
            const index = state.builder.workouts.findIndex((elem:Workout)=> elem._id === workout._id)
            state.builder.workouts.splice(index,1)
            //toast.success(`Séance du ${format(new Date(workout.schedule), "dd MMM", {locale:fr})} supprimé`)
  
        } else{
          toast.error(payload.error)
        }
      })
  
      /******** DUPLIQUER UN WORKOUT ******* */
      .addCase(pasteWorkout.pending, (state) => {
        state.requests.pasteWorkout = "pending"
  
      })
      
      .addCase(pasteWorkout.fulfilled, (state,{payload}) => {
        const workout:Workout = {...payload.workout, createdAt: payload.workout.createdAt}
        if(!workout?.template || (workout.template && workout.programTemplate)){ // exercice custom
          state.builder.workouts.push(workout)
        } else{
          toast.error(payload.error)
        }
        state.requests.pasteWorkout = "idle"
  
      })
  
      /******** DUPLIQUER PLUSIEURS WORKOUTS ******* */

      .addCase(pasteMultiplesWorkouts.pending,(state)=>{
        state.requests.pasteMultipleWorkouts = "pending"
      })

      .addCase(pasteMultiplesWorkouts.fulfilled,(state,{payload})=>{
        state.requests.pasteMultipleWorkouts = "idle"
        const workouts:Workout[] = payload.workouts
        if(workouts){
          workouts.forEach((workout:Workout)=> {
            state.builder.workouts.push(workout)
          })
        }else{
          toast.error(payload.error)
        }
      })
  
  
       /******** DELETE MULTIPLE WORKOUTS ******* */

       .addCase(deleteMultipleWorkouts.pending,(state)=>{
        state.requests.deleteMultipleWorkouts = "pending"
       })

       .addCase(deleteMultipleWorkouts.fulfilled,(state, action)=>{
          const workouts:Workout[] = action.payload.workouts
          if(workouts){
            workouts.forEach((workout:Workout) => {
                const workoutIndex = state.builder.workouts.findIndex((elem:Workout) =>  workout._id === elem._id)
                state.builder.workouts.splice(workoutIndex,1)
            })
          }else{
            toast.error('Impossible de supprimer les séances')
          }
      })

      .addCase(deleteMultipleWorkouts.rejected,(state)=>{
        toast.error('Impossible de supprimer les séances')
      })
  
       /******** MISE A JOUR MULTIPLE WORKOUTS STATUS ******* */
      .addCase(updateMultipleWorkoutsStatus.pending, (state)=>{
        state.requests.updateMultipleWorkouts = "pending"
      })
      .addCase(updateMultipleWorkoutsStatus.fulfilled, (state,{payload})=>{
        const {workouts, status} = payload
        if(workouts){
          workouts.forEach((wod)=>{
            const index = state.builder.workouts.findIndex((elem)=> elem._id === wod._id)
            if(index !== -1) state.builder.workouts[index].status = status
          })
          toast.success('Séances mises à jour')
        }
      })
      .addCase(updateMultipleWorkoutsStatus.rejected, ()=>{
        toast.error('Impossible de mettre à jour les séances')
      })
  
  
     
  
      /******** CREATE PROGRAM ******* */
  
      .addCase(createNewProgram.pending,(state)=>{
        state.requests.createProgram = "pending"
      })
  
      .addCase(createNewProgram.fulfilled,(state,{payload})=>{
        state.requests.createProgram = "idle"
        const {program} = payload
        if(!program.template){ 
            state.builder.programs.push(program)
        }
      })
  
  
      /******** UPDATE PROGRAM (Dans le planning custom) ******* */
  
      .addCase(updateProgram.pending,(state)=>{
        state.requests.update = "pending"
      })
  
      .addCase(updateProgram.fulfilled,(state,{payload})=>{
        state.requests.update = "idle"
        const {program} = payload
        if(!program?.template){ // program custom
          const index = state.builder.programs.findIndex((elem:Program)=> elem._id === program._id)
          state.builder.programs[index] = program
          toast.success(`Programme modifié`)
  
        }
      })
  
  
  
      /******** DELETE PROGRAM ******* */
  
      .addCase(deleteProgram.pending,(state)=>{
        state.requests.deleteProgram = "pending"
      })
  
      .addCase(deleteProgram.fulfilled,(state,{payload})=>{
        state.requests.deleteProgram = "idle"
        const {program} = payload
        if(!program?.template){ // program custom
          const index = state.builder.programs.findIndex((elem:Program)=> elem._id === program._id)
          state.builder.programs.splice(index,1)
  
        } 


        else{
          toast.error(payload.error)
        }
      })



    /********* RECUPERATION D'UN PROGRAM (TEMPLATE) ********* */
    .addCase(getProgram.pending, (state)=>{
      state.requests.getPlanning = "pending"
      state.builder.isReady = false
    })
    .addCase(getProgram.fulfilled, (state, {payload})=>{
        state.requests.getPlanning = "idle"
        const {program,workouts,checkups} = payload
        if(program.template){ // Template de programme
            state.builder = {
                ...program,
                workouts,
                checkups,
                isReady: true,
                workoutCopied: state.builder.workoutCopied,
                checkupCopied: state.builder.checkupCopied,
                weekCopied: state.builder.weekCopied
            }
        }
    })


    /********* RECUPERATION D'UN PROGRAM (IMPORT) ********* */
    .addCase(importProgram.pending, (state)=>{
      state.requests.importProgram = "pending"
    })
    .addCase(importProgram.fulfilled, (state)=>{
        state.requests.importProgram = "idle"
    })

    /********* UPDATE PROGRAMME DURATION (TEMPLATE) ********* */
    .addCase(updateProgramDuration.pending,(state,{payload})=>{
      state.requests.update = "pending"
    })
  
    .addCase(updateProgramDuration.fulfilled,(state,{payload})=>{
      state.requests.update = "idle"

      if(payload.program){
          const endDate = payload.program.endDate.toString()
          const startDate = payload.program.startDate.toString()
          state.builder.endDate = endDate
          const filteredWorkouts:Workout[] = state.builder.workouts.filter((wod)=> isWithinInterval(new Date(wod.schedule), {start: new Date(startDate), end: new Date(endDate)}))
          state.builder.workouts = filteredWorkouts
          toast.success('Durée modifiée')
      }else{
          toast.error('Impossible de modifier la durée du programme')
      }
    })


    /********* UPLOAD COVER ********* */

    .addCase(uploadCover.pending,(state,{payload}) => {
      state.requests.uploadCover = "pending"
    })

    .addCase(uploadCover.fulfilled,(state,{payload}) => {
      state.requests.uploadCover = "idle"
    })


     /********* DELETE COVER ********* */

     .addCase(deleteCustomCover.pending,(state,{payload}) => {
      state.requests.deleteCover = "pending"
    })

    .addCase(deleteCustomCover.fulfilled,(state,{payload}) => {
      state.requests.deleteCover = "idle"
    })


    /********* DELETE QUESTIONNAIRE ********* */

    .addCase(deleteQuestionnaire.pending,(state) => {
      state.requests.deleteQuestionnaire = "pending"
    })

    .addCase(deleteQuestionnaire.fulfilled,(state) => {
      state.requests.deleteQuestionnaire = "idle"
    })

  
    }
})


export const { 
    resetPlanning,
    defineNewPlanningRange,
    copyWorkoutPlanning,
    copyWeekPlanning,
    copyCheckupPlanning,
    handleProgramTemplateName,
    programIsSaving,
    handleDaysLabelPosition,
    handleProgramTemplateDescription,
    resetWorkoutCopied
  } = planningSlice.actions
  
  export default planningSlice.reducer

