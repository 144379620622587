import { lighten } from "@mui/material"
import { BORDER_COLOR, BORDER_WORKOUT_TEXTFIELD_COLOR, HOVER_COLOR, LIGHT_BUTTON_COLOR, WORKOUT_BUILDER_HEADER_TEXTFIELD_BGCOLOR, WORKOUT_BUILDER_TEXTFIELD_BGCOLOR, WORKOUT_BUILDER_TEXTFIELD_RADIUS } from "utils/constants"
import { theme} from "utils/theme"

const classes = {

    exercice : {
        paddingLeft: 0,
        position:"relative",
        width: "100%"
    },



    exerciceFromGroup:{
        marginBottom: 2
    },

    header : {
        height: 80,
        display:"flex",
        flex: 1,
        flexDirection: "row",
        alignItems:"center",
        justifyContent:"space-between",
        cursor: "drag",
        paddingLeft:{
            xs: 1,
            md: 0
        },
        paddingRight:{
            xs: 1,
            md: 0
        },

    },

    noteContainer:{
        width: "100%",
        marginBottom: 3,
        marginTop: 1,
    },

    note :{
        margin: 0,
        borderRadius: "10px", 
        fontStyle: "italic",
        width: "100%", 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"flex-start", 
        //backgroundColor: "whitesmoke", 
        //padding:1.5
    },

    exerciceButton:{
        borderRadius: "100px !important",
        marginRight: {
            xs: 0,
            sm:1,
        }
        //border:"solid 1px #f7f7f7",
        //backgroundColor:"#fbfbfb",
    },


    exerciceButtonLeft: {
        marginRight: 0,
        borderRadius: "3px 0px 0px 3px !important"
    },

    exerciceButtonRight: {
        marginRight: 1,
        borderRadius: "0px 3px 3px 0px !important"
    },

    exerciceButtonSelected:{
        backgroundColor: LIGHT_BUTTON_COLOR
    },

    groupedIndicator:{
        position: "absolute",
        left:`-${theme.spacing(2)}`,
        top: `calc(${theme.spacing(2)} + 30px)`,
        height: 6,
        width: 32,
        backgroundColor: "black",
        zIndex: 2
    },

    headerInfos: {
        display:"flex",
        flex: 1,
        alignItems:"center",
        justifyContent:"space-between",
        flexDirection: "row",

    },

    name: {
        fontWeight: 600,
        fontSize: {xs: "0.9rem", sm: "1rem"},
        display:"flex",
        flex: 1
    },

    sets: {
        fontWeight: 600,
        fontSize: "1.2rem",
        color: "primary.main",
        marginRight: 1
    },



    thumbnailContainer:{
        marginRight: {
            xs: 0.5,
            sm: 2
        }, 
        minWidth : {xs : 50, sm: 100}, 
        maxWidth : {xs : 50, sm: 100}, 
        minHeight: {xs : 50, sm: 60},
        maxHeight: {xs : 50, sm: 60},
        overflow: "hidden", 
        marginLeft: {
            xs: 0,
            sm: 2,
        },        
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        borderRadius: {xs: "100px !important", sm : "10px !important"},
        backgroundColor: "#ebebeb"
    },

    thumbnail : {
        minWidth : {sm: 100}, 
        maxWidth : {sm: 100}, 
        minHeight: {xs : 50, sm: 60},
        maxHeight: {xs : 50, sm: 60},
        transform: "scale(1)",
        transition: "transform 0.3s",
            "&:hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
                    transition: "transform 0.3s",
            },
    },



    setsNumber: {
        fontSize: "1rem",
        fontWeight: 700,
        //color: "#1976d2",
        color: "darkgrey",
        marginRight: 1
    },

    headerButtons: {
        display:"flex",
        flexDirection: "row",
        alignItems:"center",
        justifyContent:"center",
        
        marginLeft: 3
    },

    dropDownButton: (expand:boolean)=>({
        transform: expand ? 'rotate(180deg)' : '',
        transition: 'transform 150ms ease', // smooth transition
        //border:"solid 1px #f7f7f7",
        //backgroundColor:"#fbfbfb",
        borderRadius: "100px !important",
    }),


    exerciceActions: {
        display: "flex",
        flexDirection: "row"
    },


    settingsButton : {
        marginBottom: 2,
        display:"flex",
        justifyContent:"flex-end",
        paddingRight: 1
    },

    content:{
        paddingBottom: 3,
        
        /*paddingLeft:{
            xs: 0,
            md: 1
        },
        paddingRight: {
            xs: 0,
            md: 1
        },
        marginLeft: {
            xs: 0,
            md: 2
        },
        marginRight: {
            xs: 0,
            md: 2
        },*/
        display:"flex",
        flex: 1,
        flexDirection: "column",
        //backgroundColor: "#fcfcfc",
        marginTop: {
            xs: 0,
            sm: 2,
        },
        //border: "solid 1px whitesmoke",
        //marginBottom: 1,
        position: "relative",
        overflow: "hidden"
    },

    contentIndicator: {
        position: "absolute", 
        top: -13, 
        left: 40, 
        backgroundColor: "#fafafa",
        transform: "rotate(-45deg)",
        borderRight: `solid 1px ${BORDER_COLOR}`,
        borderTop:`solid 1px ${BORDER_COLOR}`,
        width: 26, 
        height: 26, 
        zIndex: 3
    },

    contentRow:{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems:"center",
        paddingRight: {xs: 0, sm: 1},
    },

    dropsetRow: {
        height: 50,
        //backgroundColor: "rgb(252 252 252)"
    },

    contentRowWithoutBorder:{
        borderBottom: 0
    },



    rowIndex:{
        minWidth: {
            xs: 40,
            sm:80
        },
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        fontWeight: 700,
        position: "relative"
        
    },

    setNumber:{
        fontWeight: 700,
        width: 40,
        height: 30,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        
    },

    dropset : {
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.primary.main
    },


    dropsetDescription : {
        fontSize: 13,
        display:"flex",
        flex: 1,
        marginBottom: 1
    },





   


    setsList: {
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        //marginBottom: 3, 
        flexDirection: "column",
        width: "100%"
    },


    dropsetList: {
        display: "flex",
        width: "100%",
        flexDirection: "column"
    },

    dropsetIndicator: {
        position: "absolute", 
        top: 30, 
        left: 5, 
        backgroundColor: "primary.main",
        color: "white",
        fontSize: theme.typography.pxToRem(9),
        paddingLeft: 1,
        paddingRight: 1,
        borderRadius: 10,
        cursor: "pointer",
        display:"flex",
        alignSelf: "flex-start",

        "&:hover" : {
            backgroundColor: lighten(theme.palette.primary.main, 0.1)
        }
    },

    dropsetIndicatorMobile : {
        marginLeft: "50px",
        backgroundColor: "primary.main",
        color: "white",
        fontSize: theme.typography.pxToRem(12),
        padding: 1,
        display:"inline-flex",
        marginBottom: 1,
        borderRadius: 1,
        "&:hover" : {
            backgroundColor: lighten(theme.palette.primary.main, 0.1)
        }
    },

    rowButtonsContainer:{
        minWidth: {
            xs: 48,
            sm: 96, 
        },
        maxWidth: {
            xs: 48,
            sm: 96, 
        },
        display:"flex", 
        flexDirection: "row",
        justifyContent:"flex-end"
    },

    rowButton:{
        //backgroundColor: "whitesmoke",
        marginLeft: 1
    },

    rowButtonMobile:{
        backgroundColor: LIGHT_BUTTON_COLOR,
        color: theme.palette.primary.main
    },

    prescriptionValue:{
        flex: 1,
        width: 100,
        paddingLeft: 1,
        paddingRight: 1,
        marginBottom: 1,
        height: 50,
        //borderRight: "solid 1px gainsboro",

        "&:hover":{
            backgroundColor: lighten(theme.palette.primary.main, 0.95)
        }
    },

    prescriptionTextField:{
        backgroundColor: WORKOUT_BUILDER_TEXTFIELD_BGCOLOR,
    
    
        "& .MuiOutlinedInput-root": {
            fieldset: {
                border: `solid 1px ${BORDER_COLOR}`,
                //border: "none",
            },
            fontWeight: 500,
            fontSize: "1.05rem",
            color: "#202020",

            "&.Mui-disabled":{
                fieldset: {
                    //border: "solid 1px #e2e2e2",
                    border: "none",
                },
                fontWeight: 500,
                fontSize: "1rem",
            }
        },
    },

    buttons: {
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        flexDirection:"row", 
        marginTop: {
            xs: 0,
            sm: 2
        }

    },

    mobileButton: {
        width: 35, 
        height: 35,
        borderRadius: "100px !important",
        backgroundColor: {
            xs: "#c6ddf5",
        }, 
        marginTop: 2,
        marginRight: 2,
        "&:hover":{
            backgroundColor: {
                xs: "#c6ddf5",
            }, 

        }
    },




   

    
}

export default classes