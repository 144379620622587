
import React, { useEffect} from "react"
// MUI
import {Box, useMediaQuery} from "@mui/material"
// REDUX
// DATE FNS
import { addDays, addWeeks, endOfWeek, isAfter } from "date-fns"
import { fr } from "date-fns/locale"
// Interfaces
// Styles
import classes from "./styles"
// Organisms
import PlanningWeek from "components/organisms/PlanningWeek"
import PlanningDatePicker from "components/organisms/PlanningDatePicker"
import { useSelector } from "react-redux"
import { RootState } from "app/store"
import PlanningHeaderDays from "components/molecules/PlanningHeaderDays"
import { useLocation } from "react-router-dom"
import {  MOBILE } from "utils/constants"


interface Props{
    hideDatePicker?: boolean,
    hideDayLabels?: boolean,
    startDate: Date,
    endDate:Date,
    weeks: {
        _id: string,
    }[],
    isProgramTemplate?: boolean,
    coachProfile?: boolean

}

/*********** MAIN COMPONENT ******** */

export default function Planning({hideDatePicker, hideDayLabels, weeks, startDate, endDate, isProgramTemplate, coachProfile = false}:Props){


    const location = useLocation<{planningScroll: number}>()
    const programTemplateId = useSelector((state:RootState) => state.planning.builder?._id)
    const mobile = useMediaQuery(MOBILE)
    const desktop = !mobile

    

    useEffect(()=>{
        window.scrollTo({top:location?.state?.planningScroll})
    },[location?.state?.planningScroll])
    

    return(

        <React.Fragment>
            {/****** *********************** 
            *          DATE PICKER
            * ****************************/}

            {!hideDatePicker && (
                <Box 
                    sx={[classes.planningHeader]}
                >
                    <PlanningDatePicker />
                </Box>
            )}
                

            <Box 
                sx={classes.planning}>

                {/****** ********************************************************************************* 
                *          PLANNING HEADER (DATE PICKER + LABELS ) - Uniquement sur les plannings customs
                * ******************************************************************************************/}
                
                {(!hideDayLabels && (desktop)) && (
                    <PlanningHeaderDays   
                        coachProfile={coachProfile}
                    />
                )}

                {/****** ********************************** 
                *          AFFICHAGE DES SEMAINES 
                * ************************************** */}

                <Box 
                    sx={[classes.planningContent]}
                >
                    {weeks.map((week, weekIndex) => {    
                        
                        const start:Date = new Date(addWeeks(new Date(startDate), weekIndex).setHours(0,0,0,0))
                        const end:Date = new Date(programTemplateId? new Date(addDays(start,6).setHours(23,59,59,999)) : new Date(endOfWeek(start, {locale: fr})).setHours(23,59,59,999))
                        const hidden = isAfter(start, new Date(endDate))

                        
                        

                        if(!hidden){
                            return(
                                <PlanningWeek
                                    key={week._id}
                                    start={start}
                                    end={end}
                                    weekIndex={weekIndex}
                                    isProgramTemplate={isProgramTemplate}
                                    
                                />
                            )
                        }
                        
                    })}
                </Box>
            </Box>

        </React.Fragment>
    )
}