import { Add, Timer, TimerOff } from "@mui/icons-material"
import { Box, IconButton, MenuItem, TextField, Tooltip, useMediaQuery } from "@mui/material"
import { createSelector } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "app/store"
import { addMetricToExercice, handleExerciceRest, onChangeExerciceMetric, onDeleteExerciceMetric } from "features/workoutSlice"
import { getExerciceDetails } from "function/getExerciceDetails"
import { ExerciceMetrics } from "interfaces/Exercice"
import { WorkoutExercice } from "interfaces/Workout"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { durationMetric, MOBILE } from "utils/constants"
import classes from "./styles"
import { selectExerciceHasRest, selectBlockType, selectIsLastExerciceInGroup } from "selectors/workoutSelectors"

/**** SELECTORS LIST **** */

const selectMetrics = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId)
        var listOfMetrics = [...workoutExercice.metrics]
        
        return listOfMetrics

    }
)

const selectMetricsList = createSelector(
    (state:RootState) => state.metrics,
    (metrics) => {
        const listOfMetrics = [...metrics.list]
        const list = listOfMetrics.sort((a, b) => {
            var textA = a.label.fr.toUpperCase();
            var textB = b.label.fr.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }); 

       return list
    }
)




interface Props {
    blockId: string,
    groupId: string,
    exerciceId: string,
}


export default function WorkoutExerciceMetrics({blockId, groupId, exerciceId}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const exerciceMetricsSelected = useSelector((state:RootState)=> selectMetrics(state, groupId, exerciceId, blockId))
    const blockType = useSelector((state:RootState)=> selectBlockType(state, blockId))
    const metrics:ExerciceMetrics[] = useSelector(selectMetricsList)
    const hasRest = useSelector((state:RootState)=> selectExerciceHasRest(state,groupId, exerciceId, blockId))
    const isLastGroupExercice:boolean = useSelector((state:RootState)=> selectIsLastExerciceInGroup(state,groupId, exerciceId, blockId))
    
    const mobile = useMediaQuery(MOBILE)
    

    const isTabata = useMemo(()=>{
        return Boolean(blockType) && blockType === "TABATA"
    },[blockType])

    const isEMOM = useMemo(()=>{
        return Boolean(blockType) && blockType === "EMOM"
    },[blockType])

    const isAMRAP = useMemo(()=>{
        return Boolean(blockType) && blockType === "AMRAP"
    },[blockType])

    const isClassic = useMemo(()=>{
        return !Boolean(blockType) || blockType === "classic"
    },[blockType])


    /** 
    * Modifie un metric après une sélection
    * @param {number} column - index de la colonne
    * @param {ExerciceMetrics} metric - le metric sélectionné
    */  
    const onChangeMetric = (metric:ExerciceMetrics,column:number) => () => {
        setTimeout(()=>dispatch(onChangeExerciceMetric({
            blockId, 
            exerciceId,
            column, 
            groupId,
            metric
        })), 50 )
    }

    /** 
    * Supprimer la colonne metric de l'exercice
    * @param {number} index - index de la colonne
    */  
    const onDeleteColumn = (index:number) => () => {
        dispatch(onDeleteExerciceMetric({
            blockId,
            groupId,
            exerciceId,
            metricIndex: index
        }))
    }

    /************************************************************* 
        Ajouter une colonne à la liste des metrics de l'exercice 
    **************************************************************/ 
    const onAddMetric = () => {
        const listOfUsedMetrics:string[] = []
        // On récupère l'ID de tous les metrics utilisés
        exerciceMetricsSelected.forEach((elem)=>{
            listOfUsedMetrics.push(elem._id)
        })
        // ON cherche le premier metric qui n'est pas utilisé
        const findFirstMetric = metrics.find((elem) => {
            if(!listOfUsedMetrics.includes(elem._id)) return elem
        })
        dispatch(addMetricToExercice({
            blockId,
            groupId,
            exerciceId,
            newMetric: findFirstMetric
        }))
    }

    /**************************************************** 
        Active ou non les temps de repos sur les séries
    ******************************************************/ 
    const onHandleRest = () => {
        dispatch(handleExerciceRest({
            blockId,
            groupId,
            exerciceId, 
            rest: !hasRest
        }))
        //setAnchorEl(null)
    }


    return(
        <Box 
            sx={[ 
                classes.container,
            ]} 
        >

            {/******************* 
            * NUMERO DE SERIE
            *********************/}

            {isClassic && (
                <Box sx={[
                    classes.setLabel
                ]}>
                    <Tooltip 
                        title={"Numéro de la série"}
                    >
                        <span>{mobile ? "#" : "Série"}</span>
                    </Tooltip>
                    
                </Box>
            )}
            
            {/********************************** 
            * Colonne - Metrics selectionnables
            ************************************/}
            
            {exerciceMetricsSelected.map((metric:ExerciceMetrics, metricIndex:number)=>{
                
                const metricIsDuration = metric._id === durationMetric

            
                return(

                <Box 
                    sx={classes.metric} 
                    key={metric._id}>

                        {/*********************** 
                         * TEXTFIELD DU METRIC 
                         * ******* *************/}

                        <TextField
                            sx={classes.metricTextField}
                            defaultValue={metric._id}
                            select={true}
                            fullWidth={true}
                            size="small"
                            disabled={((isTabata || isEMOM) && metricIsDuration)}
                        >

                            {/************************************ 
                             * LISTE DES OPTIONS SELECTIONNABLES
                             * **********************************/}

                            {metrics.map((optionMetric: ExerciceMetrics, optionIndex:number) => {

                                // ON Recherche les metrics déja utilisés par l'exercice pour les désactiver
                                const disabled = exerciceMetricsSelected.find(elem => elem._id === optionMetric._id) ? true : false

                                return (
                                    <MenuItem 
                                        key={optionIndex} 
                                        value={optionMetric._id} 
                                        disabled={disabled}
                                        onClick={onChangeMetric(optionMetric,metricIndex)}
                                    >
                                        {optionMetric.label.fr}
                                    </MenuItem>
                                )
                            })}
                            
                            {/********************************* 
                                SUPPRESSION D'UNE COLONNE 
                            ***********************************/}
                            {exerciceMetricsSelected.length > 1 && (
                                <MenuItem 
                                    sx={{color: "primary.main", fontWeight: 700}} 
                                    onClick={onDeleteColumn(metricIndex)}
                                >
                                    Supprimer
                                </MenuItem>
                            )}
                        </TextField>
                </Box>
                )
            })}


            {/*************************************** 
             * Colonne - TEMPS DE REPOS (DESKTOP + TABLETTE)
             ***************************************/}

            {(!mobile && hasRest) && (
                <Box 
                    sx={classes.metric}
                >
                    <TextField
                        sx={classes.metricTextField}
                        defaultValue={"Récupération"}
                        fullWidth={true}
                        size="small"
                        disabled={true}
                    />
                </Box>
            )}


            {/******************************************** 
             * Button - Ajouter une colonne (DESKTOP + TABLETTE)
             * ******* ***********************************/}
            
            {!mobile && (
                <Box 
                    sx={classes.rowButtonsContainer}
                >
                    
                        <Tooltip 
                            title={"Ajouter une colonne"} 
                            placement={"left"}
                            className={"add-metrics"}
                        >
                            <span>
                                <IconButton 
                                    onClick={onAddMetric} 
                                    sx={classes.rowButton}
                                    disabled={exerciceMetricsSelected.length === 3}
                                >
                                    <Add />
                                </IconButton>
                            </span>
                        </Tooltip>
                

                        <Tooltip 
                            title={hasRest ? "Supprimer les temps de repos" :"Ajouter des temps de repos"} 
                            placement={"left"} 
                            className={"add-rest"}>
                                <span>
                                    <IconButton 
                                        onClick={onHandleRest} 
                                        sx={classes.rowButton}
                                        disabled={!isLastGroupExercice || (isTabata || isEMOM || isAMRAP)}
                                    >
                                        {hasRest ? <TimerOff /> : <Timer/>}
                                    </IconButton>
                                </span>
                        </Tooltip>
                </Box>
            )}
        
    </Box>
    )
}