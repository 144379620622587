import { createAsyncThunk } from '@reduxjs/toolkit'
import Suggestion from 'interfaces/Suggestion'
import axios from "utils/axios"



export const getSuggestions = createAsyncThunk('suggestions/getSuggestions', async () => {
    try{
        const result = await axios.get(`suggestions`)
        const suggestions:Suggestion[] = result.data.suggestions
        return({suggestions})
  
    }catch(err:any){
        return {
            error: err.response.data?.message[0]
        }
    } 
})

export const postSuggestion = createAsyncThunk('suggestions/postSuggestion', async (data:{suggestion:Suggestion}) => {
    try{
        const result = await axios.post(`suggestions`, data.suggestion)
        const suggestion:Suggestion = result.data.suggestion
        return({suggestion})
  
    }catch(err:any){
        return {
            error: err.response.data?.message[0]
        }
    } 
})