const classes = {
    exerciceButton:{
        borderRadius: "100px !important",
        marginRight: {
            xs: 0,
            sm:1,
        }
        //border:"solid 1px #f7f7f7",
        //backgroundColor:"#fbfbfb",
    },
}

export default classes