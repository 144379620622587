import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 30000,
});

instance.defaults.withCredentials = true

instance.interceptors.response.use((response) => { return response }, async (error) => {
    const originalRequest = error.config


    if (error.config.url !== "auth/accesstoken" && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        try {
            const result = await instance.get(`auth/accesstoken`)
            const accessToken = result.data.accessToken
            instance.defaults.headers.common['authorization'] = `Bearer ${accessToken}`
            
            originalRequest.headers['authorization'] = `Bearer ${accessToken}`
            return instance(originalRequest)

        } catch (err) {
            return;
        }
    } else {
        return Promise.reject(error);
    }
});


export default instance