
import { AppDispatch, RootState } from "app/store"
import {  useDispatch, useSelector } from "react-redux"
import classes from "./styles"
import {ExerciceSet } from "interfaces/Workout"
import { Box, Button, Collapse, IconButton, styled, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { KeyboardArrowDown, Close, Add, MoveDown, Settings, Remove,  DragIndicator, CheckBox, ChevronLeft } from "@mui/icons-material"
import React, {useMemo, useState} from "react"
import { 
    addSetToExercice, 
    deleteExerciceSet,
    onChangeExerciceNote, 
} from "features/workoutSlice"
import ExerciceDetails from "components/organisms/DialogExercice"
import { SetTextField } from "components/molecules/SetTextField"
import { ExerciceMetrics } from "interfaces/Exercice"
import { RestTextField } from "components/molecules/RestTextField"
import SecondaryButton from "components/atoms/Buttons/Secondary"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogDropset from "../DialogDropset"
import { durationMetric, MOBILE, weightLifting } from "utils/constants"
import DialogExerciceSettings from "../DialogExerciceSettings"
import WorkoutExerciceMetrics from "components/molecules/WorkoutExerciceMetrics"
import WorkoutExerciceMoreButton from "components/molecules/WorkoutExerciceMoreButton"
import { selectBlockType, selectExerciceHasRest, selectExerciceImage, selectExerciceIsFromSuperset, selectExerciceMetrics, selectExerciceName, selectExerciceNote, selectExerciceSets, selectExerciceType, selectIsLastExerciceInGroup } from "selectors/workoutSelectors"
import { TransitionGroup } from 'react-transition-group';
import { theme } from "utils/theme"
import CircuitIncrement from "components/molecules/CircuitIncrement"



const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    //fontSize: 20,

    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom: "2px dashed rgb(169 169 169 / 42%)"
            
        }
    },
}))



interface Props{
    _id: string,
    exerciceId: string,
    blockId: string,
    groupId?: string,
    exerciceIndex: number,
    onOpenSwap: (exerciceId:string, groupId:string)=> void,

    provided?:any,
}


export function WorkoutExercice({
    _id,
    exerciceId, 
    blockId, 
    groupId, 
    onOpenSwap, 
    provided,
}:Props){
    const dispatch = useDispatch<AppDispatch>()
    const exerciceName = useSelector((state:RootState)=> selectExerciceName(state,groupId,exerciceId, blockId))
    const exerciceImage = useSelector((state:RootState)=> selectExerciceImage(state,groupId, exerciceId, blockId))
    const exerciceType = useSelector((state:RootState)=> selectExerciceType(state,groupId, exerciceId, blockId))
    const note = useSelector((state:RootState)=> selectExerciceNote(state,groupId, exerciceId, blockId))
    const metricsSelected = useSelector((state:RootState)=> selectExerciceMetrics(state,groupId, exerciceId, blockId))
    const isLastGroupExercice:boolean = useSelector((state:RootState)=> selectIsLastExerciceInGroup(state,groupId, exerciceId, blockId))
    const isFromSuperSet:Boolean = useSelector((state:RootState) => selectExerciceIsFromSuperset(state,groupId, blockId))
    const blockType = useSelector((state:RootState)=> selectBlockType(state, blockId))
    
    
    const sets = useSelector((state:RootState)=> selectExerciceSets(state,groupId, exerciceId, blockId))
    const hasRest = useSelector((state:RootState)=> selectExerciceHasRest(state,groupId, exerciceId,blockId))

    const [expand, setExpand] = useState<boolean>(false)
    const [openExerciceDetails, setOpenExerciceDetails] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDropsetModal, setOpenDropsetModal] = useState<boolean>(false)
    const [setToAddDropset, setSetToAddDropset] = useState<number>(null)
    const [openSettings, setOpenSettings] = useState<boolean>(false)
    const mobile = useMediaQuery(MOBILE)



    const onChangeNote = (value:string) => {
        dispatch(onChangeExerciceNote({groupId, exerciceId, blockId, note:value}))
    }
    
   
    /********************** 
     * AJOUTER UNE SERIE 
     * ******* ************/
    const onAddSet = () => {
        dispatch(addSetToExercice({blockId, groupId, setIndex:null}))
    }


    const addDropSet = (setIndex:number) => ()=>{
        setOpenDropsetModal(true)
        setSetToAddDropset(setIndex)
        //dispatch(onAddDropSet({exerciceId, blockId, groupId, setIndex}))
    }

    // SUPPRESSION D'UNE SERIE
    const onDeleteSet = (setId:string, dropset?:boolean) => ()=> {
        dispatch(deleteExerciceSet({exerciceId, blockId, setId, groupId, dropset}))       
    }


    const onDeleteLastSet = () => {
        const lastSetIndex = sets.length - 1
        const setId = sets[lastSetIndex].exerciceSetId
        dispatch(deleteExerciceSet({exerciceId, blockId, setId, groupId, dropset: false}))       
    }


    const onOpenSettings = () => {
        setOpenSettings(true)
        setAnchorEl(null)

    }


    const isClassic = useMemo(()=>{
        return !Boolean(blockType) || blockType === "classic"
    },[blockType])

    
    const isTabata = useMemo(()=>{
        return Boolean(blockType) && blockType === "TABATA"
    },[blockType])

    const isEMOM = useMemo(()=>{
        return Boolean(blockType) && blockType === "EMOM"
    },[blockType])

    const isAMRAP = useMemo(()=>{
        return Boolean(blockType) && blockType === "AMRAP"
    },[blockType])
    

    /************* JSX ********** */

    return(

        <React.Fragment>
            <Box sx={[
                classes.exercice, 
                (Boolean(groupId) && !isLastGroupExercice) && classes.exerciceFromGroup,
                 
            ]}>

                {/********************** 
                 *      HEADER 
                 * *******************/}

                <Box 
                    sx={classes.header}
                >
                    <Box 
                        sx={classes.headerInfos}
                    >
                        
                        <Box sx={{
                            flexDirection: "row", 
                            display:"flex", 
                            alignItems:"center"
                        }}>

                             {/********** 
                             * DRAG ICON 
                             * ***** */}

                             {(!isFromSuperSet) && (
                                <Box
                                    {...provided.dragHandleProps}
                                    sx={{height: "70px", display:"flex", alignItems:"center", marginRight: {xs: 1, sm: 0}}}
                                >
                                    <DragIndicator
                                        sx={{color: "black"}}
                                    />
                                </Box>
                             )}

                            {(isFromSuperSet) && (
                                <Box
                                    sx={{width: 23}}
                                >
                                    
                                </Box>
                             )}

                            {/**************************************** 
                             *                  IMAGE 
                             * ***************************************/}
                            
                            <Box sx={classes.thumbnailContainer}>
                                {exerciceImage?.url && (
                                    <Box 
                                        component={"img"} 
                                        src={exerciceImage?.url}
                                        sx={classes.thumbnail}
                                        onClick={()=> setOpenExerciceDetails(true)}
                                    />
                                )}

                                { /***** PAS D'IMAGE ***** */}
                                {(!exerciceImage?.url) && (
                                    <Box>
                                        {exerciceName.fr[0]}{exerciceName.fr[1]}
                                    </Box>
                                )}
                            </Box>
                            
                            
                            {/********************* 
                            *   TITLE + NB SERIES 
                            * *********************/}

                                
                            <Box sx={{display:"flex", flexDirection: isClassic ? "row" : "column", justifyContent: "flex-start", marginLeft: 2, alignItems:"center"}}>
                                
                                {/**** NB SERIES **** */}

                                {(!mobile && isClassic) && (
                                    <Typography sx={classes.sets}>
                                        {sets.length} * 
                                    </Typography>
                                )}

                                

                                {/***** NOM ***** */}
                                
                                <Typography sx={classes.name}>
                                    {exerciceName.fr}
                                </Typography>

                               
                            </Box>
                           
                        </Box>

                        
                    </Box>

                    {/***************************** 
                     * LISTE DES BOUTONS D'ACTION 
                     * ************************** */}

                    <Box sx={classes.headerButtons}>


                        {/************* 
                         * EXPAND 
                         * ******** */}
                    
                            <IconButton 
                                onClick={()=>setExpand(!expand)}
                                sx={[classes.dropDownButton(expand), classes.exerciceButton, {marginRight: 0}]}
                                className="collapse-exercice"
                            >
                                <KeyboardArrowDown />
                            </IconButton>
                        


                        

                        {/*****************
                         *    MORE MENU
                         * ****************/}

                        <WorkoutExerciceMoreButton 
                            blockId={blockId}
                            groupId={groupId}
                            exerciceId={exerciceId}
                            onOpenSwap={onOpenSwap}
                        />
                    
                    </Box>
                 

                </Box>

                



                {/******* CONTENT ********* */}

                <Collapse 
                    in={expand} 
                    mountOnEnter 
                    unmountOnExit
                >
                    <Box 
                        sx={classes.content}
                    >


                        {/******* 
                         * NOTE
                         ********/}

                        <DescriptionTextField
                            multiline={true}
                            fullWidth
                            value={note}
                            onChange={(e)=>onChangeNote(e.target.value)}
                            placeholder={`(Optionnel) Ajoute des instructions supplémentaires pour cet exercice`}
                            variant="standard" 
                        />

                      

                

                                {/********************************************** 
                                 * Colonnes - Liste des metrics de l'exercice
                                 ***********************************************/}

                                <WorkoutExerciceMetrics
                                    blockId={blockId}
                                    groupId={groupId}
                                    exerciceId={exerciceId}
                                />

                            

                                {/**** *************************************
                                 * LISTE DES SERIES  (CLASSIC BLOCK TYPE)
                                 * *****************************************/}


                                <Box 
                                    sx={classes.setsList}
                                >

                                    {/****************** 
                                     * Liste des séries 
                                     *******************/}
                                    <TransitionGroup
                                        style={{width: "100%"}}
                                    >
                                        {sets.map((set: ExerciceSet, setIndex: number) => {
                                        
                                            const prescriptions = set.prescriptions
                                            
                                            return(
                                                <Collapse
                                                    key={set.exerciceSetId} 
                                                    unmountOnExit={true}
                                                    mountOnEnter 
                                                >
                                                    <Box 
                                                        
                                                        sx={{flexDirection: "column", width: "100%"}}
                                                    >

                                                        {/******************** 
                                                            * SERIE 
                                                        * *****************/}

                                                        <Box sx={[classes.contentRow]}>

                                                            {/**** Numéro de série **** */}
                                                            
                                                            {(blockType === "classic" || !blockType) && (
                                                                <Box sx={classes.rowIndex}>
                                                                    <Box component="span" sx={classes.setNumber}> 
                                                                        #{setIndex + 1}
                                                                    </Box>

                                                                    {/*******************
                                                                     * DROPSET (DESKTOP)
                                                                     ********************/}

                                                                    
                                                                    {(!mobile && set.dropset) && (
                                                                        <>
                                                                            <Box 
                                                                                sx={classes.dropsetIndicator} 
                                                                                onClick={addDropSet(setIndex)}
                                                                            >
                                                                                + {set.dropset.sets} dropset{set.dropset.sets > 1 ? "s":""} 
                                                                            </Box>
                                                                            
                                                                        </>
                                                                    )}
                                                                    
                                                                </Box>
                                                            )}
                                                            
                                                            {/************************************************************* 
                                                             * CHAQUE SERIES COMPORTE DES VALEURS BASEES SUR LES METRICS 
                                                             *************************************************************/}

                                                            <Box sx={{width: "100%", display:"flex", flexDirection:"row"}}>

                                                                {prescriptions.map((prescriptionValue:any, columnIndex:number)=>{
                                                                    const metricSelected:ExerciceMetrics = metricsSelected[columnIndex]
                                                            
                                                                    // Recherche de l'unité du metric
                                                                    const unit:string = metricSelected?.units?.fr 

                                                                    return(
                                                                        <SetTextField
                                                                            key={columnIndex}
                                                                            metricDefaultValue={isClassic ?  metricSelected?.defaultValue : prescriptionValue}
                                                                            defaultValue={prescriptionValue}
                                                                            unit={unit}
                                                                            setIndex={setIndex}
                                                                            groupId={groupId}
                                                                            blockId={blockId}
                                                                            exerciceId={exerciceId}
                                                                            columnIndex={columnIndex}
                                                                            metricId={metricSelected?._id}
                                                                            type={metricSelected?.type}
                                                                            fixedValue={metricSelected?.fixedValue}
                                                                            regex={metricSelected?.regex}
                                                                            disabled={(isTabata || isEMOM) && metricSelected?._id === durationMetric}
                                                                        />
                                                                    )
                                                                })}

                                                                {/** Temps de repos Classic ou Tabata */}

                                                                {(!mobile && hasRest) &&  (

                                                                    <RestTextField
                                                                        defaultValue={isEMOM ? prescriptions.length === 1 ? "Aucun repos" : "Temps restant" : isAMRAP? "Aucune" : set.rest}
                                                                        setIndex={setIndex}
                                                                        groupId={groupId}
                                                                        blockId={blockId}
                                                                        exerciceId={exerciceId}
                                                                        disabled={isTabata || isEMOM || isAMRAP}
                                                                        isText={isEMOM || isAMRAP}
                                                                    />
                                                                )}

                                                            
                                                            </Box>
                                                        
                                                            {/**************************************** 
                                                             * LISTE DES BOUTONS D'ACTION (DESKTOP) 
                                                             * **************************************/}

                                                            {!mobile && (
                                                            
                                                                <Box sx={classes.rowButtonsContainer}>

                                                                    {/********************** 
                                                                     * AJOUTER UN DROPSET 
                                                                     * ********************/}

                                                                    <Tooltip 
                                                                        title={exerciceType === weightLifting ? "Ajouter des séries dégressives" : "Impossible d'ajouter des séries dégressives à ce type d'exercice"} 
                                                                        placement={"left"}
                                                                    >
                                                                        <span>
                                                                        <IconButton 
                                                                            onClick={addDropSet(setIndex)} 
                                                                            sx={classes.rowButton}
                                                                            disabled={exerciceType !== weightLifting || isTabata || isEMOM || isAMRAP}
                                                                        >
                                                                            <MoveDown />
                                                                        </IconButton>
                                                                        </span>
                                                                    </Tooltip>

                                                                

                                                                    {/*********************** 
                                                                     * SUPPRIMER UNE SERIE 
                                                                     * **********************/}
                                                                    
                                                                    <Tooltip title={"Supprimer la série"} placement={"right"}>
                                                                        <span>
                                                                            <IconButton 
                                                                                sx={classes.rowButton}
                                                                                onClick={onDeleteSet(set.exerciceSetId, false)}
                                                                            >
                                                                                <Close />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                    
                                                                </Box>

                                                            )}
                                                        </Box>

                                                        {/********************************
                                                         *     DROPSET (mobile)          *
                                                         *******************************/}

                                                        {(mobile && set.dropset) && (
                                                        
                                                            <Box 
                                                                sx={classes.dropsetIndicatorMobile} 
                                                                onClick={addDropSet(setIndex)}
                                                            >
                                                                + {set.dropset.sets} dropset{set.dropset.sets > 1 ? "s":""} 
                                                            </Box>
                                                        
                                                        )}

                                                        {/******************************** 
                                                            * TEMPS DE REPOS (MOBILE) 
                                                        * ******************************/}

                                                        {isClassic && (mobile && hasRest)&& (
                                                            <Box sx={classes.contentRow}>
                                                                    <Box sx={classes.rowIndex}>
                                                                        <Box component="span" sx={classes.setNumber}> 
                                                                            
                                                                        </Box>
                                                                    </Box>
                                                            
                                                                    <RestTextField
                                                                        defaultValue={set.rest}
                                                                        setIndex={setIndex}
                                                                        groupId={groupId}
                                                                        blockId={blockId}
                                                                        exerciceId={exerciceId}
                                                                    />
                                                                
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Collapse>
                                            )
                                        })}
                                    </TransitionGroup>

                                    {(!isClassic && !isTabata) && (
                                       <CircuitIncrement 
                                            blockId={blockId}
                                            exerciceId={exerciceId}
                                            groupId={groupId}
                                       />
                                    )}
                                </Box>


                                {/**** ************************
                                 * BOUTONS AJOUTER / SUPPRIMER
                                 * ************************** */}

                                <Box sx={classes.buttons}>

                                    {/************** 
                                     *    MOBILE 
                                     * ************/}

                                    {mobile && (
                                        <IconButton 
                                            onClick={onAddSet} 
                                            sx={classes.mobileButton}
                                        >
                                            <Add sx={{width: 30, height: 30, color: "rgba(0,0,0,0.85)"}}/>
                                        </IconButton>
                                    )}

                                    
                                    {mobile && (
                                        <IconButton 
                                            onClick={onDeleteLastSet} 
                                            sx={classes.mobileButton}
                                        >
                                            <Remove sx={{width: 30, height: 30, color: "rgba(0,0,0,0.85)"}}/>
                                        </IconButton>
                                    )}

                                    {mobile && (
                                        <IconButton 
                                            onClick={addDropSet(sets.length-1)} 
                                            sx={[classes.mobileButton]}
                                        >
                                            <MoveDown sx={{color: "rgba(0,0,0,0.85)", fontSize: 20}}/>
                                        </IconButton>
                                    )}

                                    {mobile && (
                                        <IconButton 
                                            onClick={onOpenSettings}
                                            sx={[classes.mobileButton]}
                                        >
                                            <Settings sx={{width: 25, height: 25, color: "rgba(0,0,0,0.85)"}}/>
                                        </IconButton>
                                    )}

                                    {mobile && (
                                        <IconButton 
                                            onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}} 
                                            sx={[
                                                classes.mobileButton, 
                                                {marginRight:0}
                                            ]}
                                        >
                                            <MoreHorizIcon sx={{width: 30, height: 30,color: "rgba(0,0,0,0.85)"}}/>
                                        </IconButton>
                                    )}



                                    {/**** Bureau *** */}

                                    {(!mobile && isClassic)&& (
                                        <SecondaryButton 
                                            onClick={onAddSet} 
                                            sx={{ borderRadius: "24px !important", marginRight: mobile ? 1.5 : 0}}
                                            startIcon={<Add/>}
                                        >
                                            Ajouter une série
                                        </SecondaryButton>
                                    )}
                                </Box>
                      
                    </Box>

                    
                </Collapse>
                
            </Box>
        


            {/******* DIALOG EXERCICE DETAIL ****** */}

            <ExerciceDetails
                open={openExerciceDetails} 
                onClose={()=> setOpenExerciceDetails(false)}
                exerciceId={_id}
            
            />


            <DialogDropset 
                open={openDropsetModal}
                onClose={()=> setOpenDropsetModal(false)}
                blockId={blockId}
                groupId={groupId}
                exerciceId={exerciceId}
                setIndex={setToAddDropset}
                dropset={sets?.[setToAddDropset]?.dropset}
            />

          

            <DialogExerciceSettings 
                open={openSettings}
                onClose={()=> setOpenSettings(false)}
                exerciceId={exerciceId}
                groupId={groupId}
                blockId={blockId}
            />




        </React.Fragment>
    )
}

function areEqual(prev:Props, next:Props){
    return prev.exerciceId === next.exerciceId && 
        prev._id === next._id && 
        prev.blockId === next.blockId && 
        prev.groupId === next.groupId && 
        prev.exerciceIndex === next.exerciceIndex
}

export default React.memo(WorkoutExercice, areEqual)