import { ExerciceMetrics } from "interfaces/Exercice";
import { GroupExercice, WorkoutBlock, WorkoutExercice } from "interfaces/Workout";
import { durationMetric } from "utils/constants";
import { v4 as uuid } from "uuid"


interface Props{
    block: WorkoutBlock,
    blockType : "classic" | "AMRAP" | "EMOM" | "TABATA" | "FORTIME",
    metrics: ExerciceMetrics[]
    
}



const createCircuitTrainingExercice = (content:GroupExercice[], exerciceIndex:number, groupIndex: number, duration: string, rest:string, metricsList:ExerciceMetrics[], blockType: "classic" | "AMRAP" | "EMOM" | "TABATA" | "FORTIME") => {

    var exercice:WorkoutExercice = {...content[groupIndex].exercices[exerciceIndex]}
    
    var metrics = [...exercice.metrics]
    var sets = [...exercice.sets]

    // Si durée existe déja on supprime
    const durationFound = exercice.metrics.findIndex((elem)=> elem._id === durationMetric)

    if(durationFound !== -1 && durationFound !== 0){
        var newPrescriptions = [...sets[0].prescriptions]

        newPrescriptions.splice(durationFound,1)
        sets[0].prescriptions = newPrescriptions

        metrics.splice(durationFound,1)
    }


    // On ajoute un metrics Durée avec la valeur par défaut d'un effort
    // Valeur Metrics
    var prescriptions = [...sets[0].prescriptions]

    if(blockType === "EMOM" || blockType === "TABATA"){
        metrics[0] = metricsList.find((elem)=> elem._id === durationMetric )
        prescriptions[0] = duration
    }
    


    // ON remplace la valeur 
    sets[0] = {
        ...sets[0],
        prescriptions,
        rest
    }

    // Suppression des séries en trop
    if(sets.length > 0){
        sets.splice(1,sets.length -1)
    }

    exercice = {
        ...exercice,
        metrics: [...metrics],
        sets : [...sets],
        restBetweenSets: true,
        circuit: blockType !== "EMOM" && blockType !== "FORTIME" ? null : exercice.circuit
    }

    console.log('exo', exercice)

    return exercice


}


const purgeCircuitTrainingData = (block:WorkoutBlock, blockType:"classic" | "AMRAP" | "EMOM" | "TABATA" | "FORTIME",) => {

    var newBlock = {...block}

    if(blockType === "TABATA"){
            newBlock.circuit = {
                rounds: 6,
                work: "00:20",
                rest: "00:20"
            }
           
    }

    else if(blockType === "EMOM"){
        newBlock.circuit = {
            rounds: 6,
            work: "01:00",
            rest: "01:00"
        }
    }

    else if(blockType === "AMRAP"){
        newBlock.circuit = {
            work: "10:00",
        }
    }

   
    newBlock.name = blockType
    newBlock.blockType= blockType
    newBlock.track = false
    
    return newBlock
}


const selectCircuitTrainingDefaultValue = (blockType: "classic" | "AMRAP" | "EMOM" | "TABATA" | "FORTIME") => {
    switch (blockType){
        case "EMOM" : {
            return {
                work: "01:00",
                rest: "01:00",
            }
        }
        case "TABATA" : {
            return {
                work: "00:20",
                rest: "00:20",
            }
        }
        case "AMRAP" : {
            return {
                work: "10:00",
                rest: "00:00",
            }
        }
       
    }
}


export default function convertBlockToCircuitTraining({block, blockType, metrics}:Props){
    var newBlock:WorkoutBlock = {...purgeCircuitTrainingData(block, blockType)}
    var content:GroupExercice[] = JSON.parse(JSON.stringify(([...block.content])))



    // Réduction des séries à 1 + suppression des supersets
    content.forEach((group, groupIndex)=> {

        // Suppression des supersets + des séries
        if(group.exercices.length > 1 ){ 

            const newContent = content.flatMap((group, index) => {
                if (index !== groupIndex) return [group]; // On garde les autres groupes
            
                // On transforme l'ancien groupe en plusieurs nouveaux groupes
                return group.exercices.map((_, exericeIndex) => {
                    const newExercice = createCircuitTrainingExercice(
                        content,
                        exericeIndex,
                        groupIndex,
                        selectCircuitTrainingDefaultValue(blockType).work,
                        selectCircuitTrainingDefaultValue(blockType).rest,
                        metrics,
                        blockType
                    );
            
                    return {
                        exercices: [{ ...newExercice }],
                        groupId: uuid(),
                    };
                });
            });

            content = newContent;
        } 
        // Suppression des séries uniquement
        else {
            group.exercices.forEach((_,exericeIndex)=> {
                const newExercice = createCircuitTrainingExercice(
                    content, 
                    exericeIndex, 
                    groupIndex, 
                    selectCircuitTrainingDefaultValue(blockType).work, 
                    selectCircuitTrainingDefaultValue(blockType).rest, 
                    metrics,
                    blockType
                )
                
                content[groupIndex].exercices[exericeIndex] = newExercice
            })
        }

        
    })

    newBlock.content = content

    return newBlock

    





}