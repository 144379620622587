import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Checkbox, ListItemText, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { onActivateExerciceCircuitSettings, onChangeExerciceCircuitEvolution, onChangeExerciceCircuitMetric, onChangeExerciceCircuitSettings, onChangeExerciceCircuitValue, onRemoveExerciceCircuitSettings } from "features/workoutSlice";
import { ExerciceMetrics } from "interfaces/Exercice";
import { ExerciceCircuitSettings } from "interfaces/Workout";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBlock, selectBlockCircuitSettings, selectExerciceCircuitValues, selectExerciceMetrics } from "selectors/workoutSelectors";
import { caloriesMetric, durationMetric, kilometersMetric, metersMetric, repsMetric, weightLifting, weightMetric } from "utils/constants";


interface Props{
    blockId: string,
    groupId: string,
    exerciceId:string
}

const trad = {
    increment: "Augmenter",
    decrement: "Réduire"
}

export default function CircuitIncrement({blockId, groupId, exerciceId}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const metrics = useSelector((state:RootState) => selectExerciceMetrics(state, groupId,exerciceId, blockId))
    const exerciceCircuitSettings = useSelector((state:RootState) => selectExerciceCircuitValues(state, groupId,exerciceId, blockId))
    const blockCircuitSettings = useSelector((state:RootState) => selectBlockCircuitSettings(state, blockId))
    //const [activated, setActivated] = useState<boolean>(false)
    const [anchorElIncrement, setAnchorElIncrement] = useState<any>(null)
    const [anchorElMetric, setAnchorElMetric] = useState<any>(null)
    //const [value, setValue] = useState<number>(1)
    //const [evolution, setEvolution] = useState<"increment" | "decrement">("increment")
    //const [metric, setMetric] = useState<string>( metrics.filter((elem) => elem?._id !== durationMetric)[0]?._id)


    const filteredMetrics = useMemo(()=>{
        return metrics.filter((elem) => {
            return elem._id == repsMetric || elem._id === caloriesMetric || elem._id === metersMetric || elem._id === kilometersMetric || elem._id === weightMetric
        })
    },[metrics])

    /*
    useEffect(()=>{
        if(filteredMetrics.length === 0){
            setActivated(false)
            onChangeExerciceCircuitSettings({blockId,groupId, exerciceId,circuit:null})
        } else {
            setMetric(filteredMetrics[0]._id)
        }
    },[filteredMetrics])
    */




    /*useEffect(()=>{
        if(exerciceCircuitSettings && !activated){
            setActivated(true)
        }
    },[exerciceCircuitSettings])*/


    /*const CIRCUIT:ExerciceCircuitSettings = useMemo(()=>{
        return {
            evolution,
            value,
            metric
        }
    },[value, evolution, metric])*/

    /*useEffect(()=>{
        dispatch(onChangeExerciceCircuitSettings({
            blockId,
            groupId, 
            exerciceId,
            circuit:CIRCUIT
        }))
        if(anchorElIncrement) setAnchorElIncrement(null)
        if(anchorElMetric) setAnchorElMetric(null)
    },[CIRCUIT])*/


    const onOpenIncrementMenu = (e:any) => {
        e.stopPropagation()
        e.preventDefault()
        setAnchorElIncrement(e.currentTarget)
    }

    const onCloseIncrementMenu = () => {
        setAnchorElIncrement(null)

    }

    const onOpenDecrementMenu = (e:any) => {
        e.stopPropagation()
        e.preventDefault()
        setAnchorElMetric(e.currentTarget)
    }

    const onCloseMetricsMenu = () => {
        setAnchorElMetric(null)

    }

    /*useEffect(()=>{
        // Activation du exerciceCircuitSettings
        if(!exerciceCircuitSettings && activated){
            const settings:ExerciceCircuitSettings = {
                evolution : "increment",
                metric : filteredMetrics[0]._id,
                value: 1
            }
            dispatch(onChangeExerciceCircuitSettings({blockId,groupId, exerciceId,circuit:settings}))
        } else {
            dispatch(onChangeExerciceCircuitSettings({blockId,groupId, exerciceId,circuit: null}))

        }
    },[activated])*/




    const onHandleCheckbox = (e:React.ChangeEvent<HTMLInputElement>) => {
        const metric = metrics.filter((elem) => elem?._id !== durationMetric)[0]?._id
        if(Boolean(exerciceCircuitSettings?.evolution)){
            console.log('uncheck')
            dispatch(onRemoveExerciceCircuitSettings({blockId, groupId,exerciceId}))
        }else{
            dispatch(onActivateExerciceCircuitSettings({blockId, groupId,exerciceId, metric}))
        }
    }


    const onChangeEvolution = (newEvolution:"increment"|"decrement") => {
        dispatch(onChangeExerciceCircuitEvolution({blockId, groupId,exerciceId, evolution: newEvolution}))
        setAnchorElIncrement(null)
        
    }

    const onChangeValue = (value:number) => {
        dispatch(onChangeExerciceCircuitValue({blockId, groupId,exerciceId, value}))
        
        
    }

    const onChangeMetric = (metric:ExerciceMetrics) => {
        dispatch(onChangeExerciceCircuitMetric({blockId, groupId,exerciceId, metric: metric._id}))
        setAnchorElMetric(null)
        
    }

    return(
        <>
        <Box
            sx={{display:"flex", flexDirection:"row", mt:1.5, alignItems: "center", justifyContent: "flex-start", width: "100%"}}
        >
            {(filteredMetrics.length > 0 && blockCircuitSettings.rounds > 1) &&(
                <Box
                    sx={{display:"flex", flexDirection:"row", alignItems:"center"}}
                >
                    <Checkbox 
                        sx={{mr: 1.5}} 
                        checked={Boolean(exerciceCircuitSettings?.evolution)}
                        onChange={onHandleCheckbox}
                        disabled={filteredMetrics.length === 0}
                    />

                    {!Boolean(exerciceCircuitSettings?.evolution) && (
                        <Typography>
                            Modifier à chaque tour
                        </Typography>
                    )}
                </Box>
            )}
            {(Boolean(exerciceCircuitSettings?.evolution) && blockCircuitSettings.rounds > 1) && (
                <>


                {/******************
                 * EVOLUTION SELECTED
                 *****************/}

                <Button
                    sx={{display:"inline-flex",  cursor: "pointer"}}
                    onClick={onOpenIncrementMenu}
                    disabled={!Boolean(exerciceCircuitSettings?.evolution)}
                >
                    <Typography>
                        {exerciceCircuitSettings?.evolution ? trad[exerciceCircuitSettings.evolution] : "Augmenter"}
                    </Typography>
                    <ChevronLeft 
                        sx={{rotate: "-90deg" }}
                    />
                </Button>

                {/******************
                 * MENU EVOLUTION
                 *****************/}

                <Menu
                    open={Boolean(anchorElIncrement)}
                    onClose={onCloseIncrementMenu}
                    anchorEl={anchorElIncrement}
                >
                
                    <MenuItem
                        onClick={()=> onChangeEvolution('increment')}
                    >
                        <ListItemText>
                            Augmenter
                        </ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={()=> onChangeEvolution('decrement')}
                    >
                        <ListItemText>
                            Diminuer
                        </ListItemText>
                    </MenuItem>
                </Menu>


                {/******************
                 * METRIC SELECTED
                 *****************/}

          
                <Button
                    sx={{display:"inline-flex", ml:1.5, mr: 1.5, cursor: "pointer"}}
                    onClick={onOpenDecrementMenu}
                    disabled={!Boolean(exerciceCircuitSettings?.evolution)}
                >
                    <Typography>
                        {filteredMetrics.find((elem)=> elem._id === exerciceCircuitSettings?.metric)?.label.fr}
                    </Typography>
                    <ChevronLeft 
                        sx={{rotate: "-90deg" }}
                    />
                </Button>

                {/******************
                 * MENU METRICS
                 *****************/}

                <Menu
                    open={Boolean(anchorElMetric)}
                    onClose={onCloseMetricsMenu}
                    anchorEl={anchorElMetric}
                >

                    {filteredMetrics.map((metric) => {
                        return(
                            <MenuItem
                                key={metric._id}
                                onClick={()=>onChangeMetric(metric)}
                            >
                                <ListItemText>
                                    {metric.label.fr}
                                </ListItemText>
                            </MenuItem>
                        )
                    })}
                </Menu>

                {/********
                 * VALUE
                 ********/}


                <Typography>
                    de
                </Typography>
                <TextField 
                    size="small"
                    sx={{marginLeft: 1.5, marginRight: 1.5, width: "100px"}}
                    disabled={!Boolean(exerciceCircuitSettings?.evolution)}
                    value={exerciceCircuitSettings?.value}
                    onChange={(e)=> onChangeValue(parseInt(e.target.value))}
                    InputProps={{
                        endAdornment: `${metrics.find((elem) => elem?._id === exerciceCircuitSettings?.metric)?.units?.fr}`
                    }}
                />
                <Typography>
                    à chaque tour
                </Typography>
                </>
                )}
        </Box>
    </>
    )
}