import { useEffect, useState } from "react";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// MUI
import { Box, Typography, Grid, useMediaQuery} from "@mui/material";
// APP
import { AppDispatch, RootState } from "app/store";
// STYLES
import classes from "./styles";
// LOcal component
import { handleSubscriptionPresentation, selectDrawerItem } from "features/appSlice";
import MainContent from "components/molecules/MainContent";
import {Info} from "@mui/icons-material";
import {MOBILE } from "utils/constants";
import Navbar from "components/organisms/NavBar";
import ActivitiesHistory from "components/organisms/ActivitiesHistory";
import Tabs from "components/molecules/Tabs";
import SuggestionCard from "components/molecules/SuggestionCard";
import Suggestions from "components/organisms/Suggestions";

const tabsLabel = [
    "Historique",
    "Suggestions",  
    
]


/***************** 
 * MAIN COMPONENT 
 * *************** */

export default function Home(){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.user.data)
    const [tabs, setTabs] = useState<number>(0)
    const mobile = useMediaQuery(MOBILE)

    /********** GESTION DES TABS *********** */
    const defineTabs = (index:number) =>{
        setTabs(index)
    }
   

    /********** DRAWER SELECTION ******** */

    useEffect(()=>{
        dispatch(selectDrawerItem(0))
    },[dispatch])


    /********** PAS DE FORFAIT ******** */

    useEffect(()=>{
        if(!user.trialIsDone){
            setTimeout(()=>{
                dispatch(handleSubscriptionPresentation({open:true}))
            },1000)
            
        }
    },[user])


    return(
        <>

            {/**************
             * NAVIGATION
             ***************/}

            <Navbar
                pageTitle="Tableau de bord"
                expanded={false}
                showInstagram={true}
                
            />


            {/**************
             * CONTENT
             ***************/}


            <MainContent header="simple">

                

                {mobile && (
                    <Box
                        sx={classes.mobileMessage}
                    >
                        <Info 
                            sx={{marginRight: 2,
                                color: "#549e9e"
                            }}
                        />
                        <Typography>
                            Traener est optimisé pour une utilisation sur tablette ou PC.
                        </Typography>
                    </Box>
                )}



                <Grid 
                    container 
                    justifyContent="center" 
                    spacing={0}
                >
                    <Grid 
                        item xs={12} 
                        sm={12} 
                        md={12} 
                        lg={10} 
                        xl={8}
                    >
                        {/*<Tabs
                            value={tabs}
                            onChange={(e,value)=>defineTabs(value)}
                            tabs={tabsLabel}
                        />
                        <Box 
                            sx={classes.container}
                        >
                            {tabs === 0 && (
                                <ActivitiesHistory />
                            )}

                            {tabs === 1 && (
                                <Suggestions />
                            )}
  
                        </Box>
                        */}
                        <Box 
                            sx={classes.container}
                        >
                            <ActivitiesHistory />

                        </Box>
                    </Grid>
                </Grid>
            </MainContent>

           

           
        </>
    )
}