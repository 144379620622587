import { WORKOUT_BUILDER_TEXTFIELD_RADIUS } from "utils/constants"
import { theme } from "utils/theme"

const classes = {
    container: {
        marginBottom: 1,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems:"center",
        paddingRight: {xs: 0, sm: 1},
    },

    setLabel:{
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(15),
        position: "relative",
        minWidth: {
            xs: 40,
            sm:80
        },
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
    },

    metric:{
        flex: 1,
        width: 100,
        paddingLeft: 1,
        paddingRight: 1,
    },

    metricTextField:{
        //backgroundColor:WORKOUT_BUILDER_HEADER_TEXTFIELD_BGCOLOR,
        borderRadius: WORKOUT_BUILDER_TEXTFIELD_RADIUS,
        paddingLeft: "0 !important",
        backgroundColor: "white",
        
    
        "& .MuiOutlinedInput-root": {
            fieldset: {
                borderColor: "transparent",
                //border: `solid 1px ${BORDER_COLOR}`,
                //border: "none"
            },
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(15),
            color: "rgba(0,0,0,0.85)",
            //textTransform: "uppercase",

            "&.Mui-disabled":{
                fieldset: {
                    border: `transparent`,

                },
            },

            "& .Mui-disabled":{
                fieldset: {
                    border: "transparent",
                },
                fontWeight: 600,
                fontSize: theme.typography.pxToRem(15),
                //textTransform: "uppercase",
                color: "rgba(0, 0, 0, 0.87)",
                "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87)"
                
            }
        },

        
    },

    rowButtonsContainer:{
        minWidth: {
            xs: 48,
            sm: 96, 
        },
        maxWidth: {
            xs: 48,
            sm: 96, 
        },
        display:"flex", 
        flexDirection: "row",
        justifyContent:"flex-end"
    },

    rowButton:{
        marginLeft: 1
    },
    

}

export default classes