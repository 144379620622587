
import { Dialog, DialogContent, DialogActions, Button, DialogTitle } from "@mui/material";
import { getWorkouts, updateMultipleWorkoutsStatus } from "api/workouts";
import { AppDispatch, RootState } from "app/store";
import User from "interfaces/User";
import Workout from "interfaces/Workout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingButton from "components/atoms/Buttons/LoadingButton";
import { format } from "date-fns";
import { fr } from "date-fns/locale";


//***************** INTERFACES ********* */


interface DateRange{
    start:string,
    end: string
}

interface Props{
    open: boolean,
    onClose: ()=> void,
    dateRange:DateRange,
    action: "disable" | "enable",
}

//***************** REDUX SELECTORS ******************** */




export default function DialogActivateWorkouts({open, onClose, dateRange, action}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector((state:RootState) => state.planning.user,)
    const [workouts, setWorkouts] = useState<Workout[]>([])
    const pending:boolean = useSelector((state:RootState) => state.workouts.requests.updateMultiple === "pending")


    useEffect(()=>{
        if(open){
            
            dispatch(getWorkouts({
                start: dateRange.start, 
                end: dateRange.end, 
                createdFor: user._id, 
            })).unwrap().then((res)=>{
                if(res.workouts) {
                    var workoutsToDisplay = []
                    if(action === "enable"){
                         workoutsToDisplay = res.workouts.filter((wod)=> wod.status ==="disabled")
                    }else{
                         workoutsToDisplay = res.workouts.filter((wod)=> wod.status ==="enabled")
                    }
                    setWorkouts(workoutsToDisplay)
                }
                else toast.error('Impossible de récupérer la liste des séances')
            })
        }
    },[open])


    const onSubmit = () => {
        const newStatus = action === "enable" ? "enabled" : "disabled" // Le status des séances à changer
        dispatch(updateMultipleWorkoutsStatus({workouts, status:newStatus})).unwrap().then((res:any)=>{
            onClose()
        })
    }



        return(
            

            <Dialog open={open}>
                <DialogTitle>
                {action === "enable" ? "Activer" : "Désactiver"} {workouts.length} séance(s)
                </DialogTitle>
                
            
                <DialogContent>
                    {action === "enable" ? 
                        "Ces séances deviendront visibles sur l'application mobile." : 
                        "Ces séances ne seront plus visibles sur l'application mobile."
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose} disabled={pending}>Annuler</Button>
                    <LoadingButton onClick={onSubmit} loading={pending} disabled={pending || workouts.length === 0} variant='contained'>
                        Confirmer
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        )
    
}