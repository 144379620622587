import { createSlice } from '@reduxjs/toolkit'
import { getClientsHistory } from 'api/clients'
import { postSuggestion } from 'api/suggestions'
import Suggestion from 'interfaces/Suggestion'
import { HistoryEventDetails } from 'interfaces/User'

/**
 * INIT
 */

interface Dashboard{
    history: HistoryEventDetails[],
    suggestions: Suggestion[],
    requests:{
        get: "idle" | "pending",
        getSuggestions: "idle" | "pending",
        postSuggestions: "idle" | "pending",
    },
    filters: string[]
}

const initialState = {
    history: [],
    requests:{
        get:"idle",
        getSuggestions: "idle",
        postSuggestions: "idle",
    },
    suggestions : [{
        _id: "123",
        title: "Pouvoir créer des factures pour ses clients",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        createdBy: {_id: "123", firstname: "Samuel", lastname: "De Sousa"},
        createdAt: new Date(),
        votes:[
            {
                userId: "123",
                type: "upvote"
            },
            {
                userId: "123",
                type: "upvote"
            },
            {
                userId: "123",
                type: "downvote"
            }
        ],
        comments: [
            {
                createdBy: {_id: "123", firstname: "Samuel", lastname: "De Sousa"},
                createdAt: new Date(),
                updatedAt: new Date(),
                comment: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
            }
        ]
    }],
    filters:[]
} as Dashboard


export const dashboardSlice = createSlice({
    name:"dashboard",
    initialState,
    reducers:{
       addDashboardEventFilter : (state,action) => {
        const newFiler:string = action.payload.newFilter
        state.filters.push(newFiler)
      },

      removeDashboardEventFilter : (state,action) => {
        const filter = action.payload.newFilter
        if(filter === "all"){
            state.filters = []
            return
        }
        const index = state.filters.findIndex((elem)=> elem === filter)
        if(index !== -1){
            state.filters.splice(index,1)
        }
      },

      clearDashboardEventsFilters : (state) => {
        state.filters = []
      }
    },
    extraReducers(builder){
        builder

        .addCase(getClientsHistory.pending, (state)=>{
            state.requests.get = "pending"
        })

        .addCase(getClientsHistory.fulfilled, (state, {payload})=>{
            var currentHistory:HistoryEventDetails[] = payload.history
            // Trie par date
            currentHistory = currentHistory.sort((a,b)=> new Date(b.date).getTime() - new Date(a.date).getTime())

            state.history = currentHistory
            state.requests.get = "idle"
        })


        .addCase(postSuggestion.pending, (state, {payload})=>{
            state.requests.getSuggestions = "pending"
        })


        .addCase(postSuggestion.fulfilled, (state, {payload})=>{
            var suggestion = payload.suggestion
            
            if(suggestion){
                state.suggestions.push(suggestion)
            }
 
            state.requests.getSuggestions = "idle"

        })
    }
})


export const { 
    addDashboardEventFilter,
    removeDashboardEventFilter,
    clearDashboardEventsFilters
  } = dashboardSlice.actions

export default dashboardSlice.reducer



