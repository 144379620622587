import React, { useState, useMemo } from "react";
import { Box, Card, IconButton,styled,TextField,Typography, useMediaQuery, } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { onChangeBlockRest, onChangeBlockRounds, onChangeBlockType, onChangeBlockWork } from "features/workoutSlice";
import { GroupExercice, WorkoutBlock as WorkoutBlockType, WorkoutExercice} from "interfaces/Workout";
import { useDispatch, useSelector } from "react-redux";
import classes from "./styles"
import { createSelector } from "@reduxjs/toolkit";
import DialogExercicesList from "components/organisms/DialogExercicesList";
import Exercice from "../WorkoutExercice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Add,Delete, DragIndicator, MobileFriendly, PhonelinkErase } from "@mui/icons-material";
import DialogBlockDelete from "components/molecules/DialogBlockDelete";
import BlockTitle from "components/molecules/WorkoutBlockName";
import 'draft-js/dist/Draft.css';
import 'react-quill/dist/quill.snow.css';
import "./editorStyle.css"
import DialogBlockTracking from "../DialogBlockTracking";
import { MOBILE } from "utils/constants";
import SecondaryButton from "components/atoms/Buttons/Secondary";
import TimeTextField from "components/molecules/TimeTextField";
import BlockTypeButton from "components/molecules/BlockTypeButton";
import BlockTypeTextfield from "components/molecules/BlockTypeTextField";
import { ExerciceMetrics } from "interfaces/Exercice";
import formatMinutes from "function/formatMinutes";
import { theme } from "utils/theme";
import generateWodDescription from "function/generateWodDescription";


const DescriptionTextField = styled(TextField)(() => ({
   
    fontWeight: 700,
    border: 0,
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    //fontSize: 20,


    "& .MuiInput-root": {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        borderBottom: 0,
        fieldset: {
            border: 0
        },
        "&::before": {
            border: 0,
            borderBottom:0
            
        }
    },
    
}))





////// REDUX SELECTORS ///////

const nameSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.name
    }
)


const contentSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.content
    }
)


const selectBlockCircuit = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.circuit
    }
)


const trackSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.track
    }
)


const typeSelector = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, id) => {
        const block:WorkoutBlockType = builder.blocks.find((elem:WorkoutBlockType)=> elem.blockId === id)
        return block?.blockType
    }
)




const metricsSelector = createSelector(
    (state:RootState) => state.metrics,
    (metrics) => {
        const listOfMetrics = [...metrics.list]
        const list = listOfMetrics.sort((a, b) => {
            var textA = a.label.fr.toUpperCase();
            var textB = b.label.fr.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }); 

       return list
    }
)



function TrackerButton({track, openBlockTracking, disabled}:{track:boolean, disabled?:boolean, openBlockTracking: (tracker:boolean)=> void}){

    return(

        <Box 
            sx={[
                classes.trackingButton, 
                !track && classes.trackingButtonOff,
                disabled && {
                    backgroundColor: "#c6ddf5",
                    "&:hover" : {
                        backgroundColor: "#c6ddf5",
                        cursor: "auto"
                    }
                }
            ]} 
            onClick={()=> {
                if(!disabled){
                openBlockTracking(true)
                }
            }}
        >
            {track && (
            <MobileFriendly 
                sx={{fontSize: 18, marginRight: 1, color:"white"}} 
            />)}

            {!track && (
                <PhonelinkErase 
                sx={{fontSize: 18, marginRight: 1, color: "#bcbcbc"}} 
            />
            )}
            
            <Typography 
                sx={[
                    classes.trackingButtonLabel, 
                    !track && classes.trackingButtonLabelOff
                ]}>
                    {track ? "Avec suivi des performances" : "Sans suivi des performances"}
            </Typography>
        </Box>
    )
}


////// INTERFACES ///////
interface Props {
    blockId: string, // Id unique du block
    blockIndex: number,
    showAddBlock: boolean,
    onAddBlockToWorkout: ()=> void
}


/*********** MAIN COMPONENT ********* */

export function WorkoutBlock({blockId, blockIndex, showAddBlock}:Props){

    const dispatch = useDispatch<AppDispatch>()
    const name:string = useSelector((state:RootState)=>nameSelector(state,blockId))
    const content:GroupExercice[] = useSelector((state:RootState)=>contentSelector(state,blockId))
    const circuit = useSelector((state:RootState)=>selectBlockCircuit(state,blockId))
    const track:boolean = useSelector((state:RootState)=>trackSelector(state,blockId))
    const blockType = useSelector((state:RootState)=> typeSelector(state,blockId))
    const performed = useSelector((state:RootState)=> state.workouts.builder.performed)
    const [openList, setOpenList] = useState<boolean>(false)
    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openBlockTracking, setOpenBlockTracking] = useState<boolean>(false)
    const [exerciceToSwap, setExerciceToSwap] = useState<{groupId:string, exerciceId: string}>(null)
    const mobile = useMediaQuery(MOBILE)
    const metricsList:ExerciceMetrics[] = useSelector(metricsSelector)


    const isClassic = useMemo(()=>{
        return (Boolean(blockType) && blockType === "classic") || !blockType
    },[blockType])


    const onOpenDeleteBlock = () => {
        setOpenDelete(true)
        setAnchorEl(null)
    }


    const onOpenSwap = (exerciceId:string, groupId:string) => {
        setOpenList(true)
        setExerciceToSwap({
            groupId,
            exerciceId,
        })
    }


    const onCloseExercicesList = () => {
        setOpenList(false)
        setTimeout(()=>{
            setExerciceToSwap(null)
        },200)
        
    }




    const changeBlockType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as "classic" | "AMRAP" | "EMOM" | "TABATA" | "FORTIME"

        dispatch(onChangeBlockType({
            blockId,
            blockType: value,
            metricsList
        }))
    }

    


    const changeBlockRounds = (value:any) => {
        dispatch(onChangeBlockRounds({blockId, rounds:value}))
    }   

    const changeBlockWork = (value:any) => {
        dispatch(onChangeBlockWork({blockId, work:value}))
    }

    const changeBlockRest = (value:any) => {
        dispatch(onChangeBlockRest({blockId, rest:value}))
    }

   


    const isCircuitTraining = useMemo(()=>{
        return blockType && blockType !== "classic"
    },[blockType])

 
    /************ JSX ************ */

    return(

        <Box 
            sx={classes.block}
        >

            {/*********************** 
            *     HEADER DU BLOCK
            *  ********************* */}

            <Box sx={[
                classes.header, 
                content.length ===0 && classes.headerWithBorder
            ] }>

                {/*************** 
                 * TITRE DU BLOC 
                 * *************/}

                <Box sx={{display:"flex", flexDirection: "row", alignItems:"center"}}>

                    {/***************** 
                     * BOUTON TRACKING 
                     * ****************/}

                        <Typography
                            sx={classes.blockTitle}
                        >
                            #{blockIndex + 1} - &nbsp;
                        </Typography>
                        <BlockTitle
                            blockId={blockId}
                            name={name}
                            className={"block-name"}
                            blockIndex={blockIndex}
                            disabled={Boolean(performed)}
                            
                        />
                </Box>


                {/****************************** 
                 * LISTE DES BOUTONS DU BLOCK 
                 * *************************** */}

                
                <Box 
                    sx={classes.blockButtons}
                >

                    {/************************* 
                     * TRACKER DESKTOP ONLY
                     **************************/}
                    {!mobile && (
                        <TrackerButton 
                            openBlockTracking={setOpenBlockTracking}
                            track={track}
                            disabled={isCircuitTraining}
                        />
                    )}

                    {!Boolean(performed) && (
                        <IconButton 
                            sx={{marginLeft: 2, backgroundColor: "whitesmoke"}} 
                            onClick={onOpenDeleteBlock}
                        >
                            <Delete />
                        </IconButton>
                    )}

                </Box>
              
            </Box>


            {/****************************** 
            *     EMOM, AMRAP, TABATA
            *  *************************** */}

            <Box 
                sx={{display:"flex", flexDirection:"row",marginTop: 2}}
            >

                {/**************** 
                 * TYPE DE BLOCK
                 *****************/}

                <BlockTypeButton  
                    changeBlockType={changeBlockType}
                    blockType={blockType}
                />
            
                {/*********
                 * TABATA
                 **********/}

                {blockType === "TABATA" && (
                    <>
                        <BlockTypeTextfield
                            value={circuit?.rounds}
                            startAdornment="Tours : "
                            changeValue={changeBlockRounds}
                        />

                        <TimeTextField
                            defaultValue={circuit?.work}
                            blockId={blockId}
                            startAdornment="Effort : "
                            changeValue={changeBlockWork}
                            onBlurValue={"00:20"}
                        />
                        <TimeTextField
                            defaultValue={circuit?.rest}
                            blockId={blockId}
                            startAdornment="Repos : "
                            changeValue={changeBlockRest}
                            onBlurValue={"00:20"}

                        />
                        
                    </>

                )}

                 {/*********
                 * EMOM
                 **********/}

                {blockType === "EMOM" && (
                    <Box
                        sx={{display:"flex", justifyContent:"flex-start", flexDirection:"column"}}
                    >
                        <Box
                            sx={{display:"flex", flexDirection: "row"}}
                        >
                            
                            <BlockTypeTextfield
                                value={circuit?.rounds}
                                startAdornment="Tours : "
                                changeValue={changeBlockRounds}
                                width={180}
                                
                            />

                            <TimeTextField
                                defaultValue={formatMinutes(circuit?.rounds * content.length)}
                                blockId={blockId}
                                startAdornment="Durée : "
                                disabled={true}

                            />

                      
                        </Box>

                       
                    </Box>

                )}

                

                {/*********
                 * AMRAP
                 **********/}

                {blockType === "AMRAP" && (
                    <>
                        <TimeTextField
                            defaultValue={circuit?.work}
                            blockId={blockId}
                            startAdornment="Durée : "
                            changeValue={changeBlockWork}
                            onBlurValue={"12:00"}
                            blockSeconds={true}

                        />
                    </>

                )}

                {blockType === "FORTIME" && (
                    <>
                        <BlockTypeTextfield
                            value={circuit?.rounds}
                            startAdornment="Tours : "
                            changeValue={changeBlockRounds}
                            width={180}
                        />
                    </>

                )}

            </Box>
            
            {!isClassic && (
                <Box
                    sx={{marginTop: 3, marginBottom: 5}}
                >
                    <Typography
                        dangerouslySetInnerHTML={{__html: generateWodDescription(blockType, content,circuit)}}
                    />
                     
                    
                    

                </Box>
            )}
            


                {/*blockType === "EMOM" && (
                    <Box
                        sx={classes.everyMinutes}
                        onClick={onChangeEMOMType}
                    >
                        1 exercice / minute <SwapHoriz sx={{marginLeft: 1.5}} />
                    </Box>
                )*/}


                
                {/*blockType === "AMRAP" && (
                    <Box
                        sx={[classes.everyMinutes, {width : 200}]}
                    >
                        Maximum de tours
                    </Box>
                )}

                {blockType === "FORTIME" && (
                    <Box
                        sx={[classes.everyMinutes, {width : 150}]}
                    >
                        Meilleur temps
                    </Box>
                )}*/}




            {/** TRACKER MOBILE ONLY**/}

            {mobile && (
                <TrackerButton 
                    openBlockTracking={setOpenBlockTracking}
                    track={track}
                />
            )}
            

            {/******************* 
            * CONTENU DU BLOC 
            * **************** */}
            
            <Box>


                <Droppable 
                    droppableId={blockId}
                >
                    {(provided: any) => (
                        <Box 
                            ref={provided.innerRef} 
                            {...provided.droppableProps}
                            sx={[
                                classes.dropZone, 
                                content.length ===0 && classes.emptyBlock
                            ]} 
                        >

                            {/***** ********
                             * EMPTY BLOCK
                             * ****** *****/}

                            {content.length ===0 && (
                                <Box>
                                    <Typography
                                        sx={{fontWeight: 600, fontSize: "1.1rem", color: "rgb(82 82 82 / 75%)"}}
                                    >
                                        Aucun exercice ajouté
                                    </Typography>
                                </Box>
                            )}
                            

                            {/***** **********************************************
                             * LISTE DES ELEMENTS DRAGGABLE (EXERCICE OU SUPERSET) 
                             * ****** ******************************************/}

                            {content.map((group:GroupExercice, index:number)=> {

                                const isLastBlockElement:boolean = index === content.length - 1

                                return(
                                    <Draggable 
                                        draggableId={group.groupId} 
                                        index={index}
                                        key={group.groupId}
                                        isDragDisabled={Boolean(performed)}
                                    >
                                        
                                        {(provided: any, snapshot: any) => (
                                            <Card
                                                elevation={snapshot.isDragging? 1 : 0} 
                                                ref={provided.innerRef} 
                                                {...provided.draggableProps} 
                                                sx={[classes.draggableElement(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                ),{
                                                    marginLeft: isCircuitTraining? "50px" : "0px",
                                                    maxWidth: isCircuitTraining? "calc(100% - 50px)" : "100%"
                                                    
                                                }]}
                                            >
                                                

                                                {/*********************
                                                 * CIRCUIT INDICATORS
                                                 *********************/}
                                                {isCircuitTraining && (
                                                    <Box
                                                        sx={[classes.emomIndicator]}
                                                    />
                                                )}

                                                
                                                <Box sx={{position:"relative", width: "100%"}}>

                                                    {isCircuitTraining && (
                                                        <Box
                                                            sx={[
                                                                classes.blockEmomIndicator,
                                                                isLastBlockElement && classes.blockEmomIndicatorLast
                                                            ]}
                                                        >

                                                        </Box>
                                                    )}


                                                    {/******************** 
                                                     * SUPERSET INDICATOR 
                                                     * ******************/}

                                                    {(mobile && group.exercices.length > 1 && blockType !== "EMOM") && (
                                                        <Box sx={classes.superSetIndicator} />
                                                    
                                                    )}
                                                
                                                    <Box 
                                                        sx={classes.group} 
                                                        key={index}
                                                    >


                                                    {/****************** 
                                                     * DRAG INDICATOR 
                                                     * ****************/}

                                                    {group.exercices.length > 1 && (
                                                        <Box sx={{flexDirection: "row", display:"flex", alignItems:"center"}}>
                                                            <Box
                                                                {...provided.dragHandleProps}
                                                                sx={{
                                                                    height: "50px", 
                                                                    display:"flex", 
                                                                    alignItems:"center", 
                                                                    cursor: Boolean(performed) ? "auto !important" :"grab !important"
                                                                }}
                                                            >
                                                                <DragIndicator />
                                                            </Box>

                                                 
                                                       
                                                            <Box sx={classes.supersetLabel}>
                                                                Superset ({group.exercices.length})
                                                            </Box>
                                                            
                                                        </Box>

                                                        
                                                        
                                                    )}

                                                    {/*********************** 
                                                     * SUPERSET INDICATOR 
                                                     * **** ***************/}

                                                    {(!mobile && group.exercices.length > 1 && blockType !== "EMOM") && (
                                                        <Box sx={classes.superSetIndicator} />
                                                    )}

                                                    {/*****************
                                                    Liste des exercices 
                                                    *******************/}
                                                    
                                                    <Box 
                                                        sx={[
                                                            classes.groupContent,
                                                            //isCircuitTraining && classes.circuitTraining
                                                        ]}
                                                    >

                                                        {/*(group.exercices.length === 1 && blockType === "EMOM") && (
                                                                <Box sx={classes.supersetLabel}>
                                                                    En 1 minute
                                                                </Box>
                                                        )*/}

                                                        {group.exercices.map((workoutExercice:WorkoutExercice, exerciceIndex: number)=> {

                                                            return(
                                                                <Exercice 
                                                                    key={exerciceIndex}
                                                                    _id={workoutExercice.exercice?._id}
                                                                    exerciceId={workoutExercice.exerciceId}
                                                                    blockId={blockId}
                                                                    groupId={group.groupId}
                                                                    exerciceIndex={exerciceIndex}
                                                                    onOpenSwap={onOpenSwap}
                                                                  
                                                                    provided={provided}
                                                                />
                                                            )
                                                        })}

                                                    </Box>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        )}

                                    </Draggable>
                                )
                            })}

                            {provided.placeholder}

                        </Box>
                    )}


                </Droppable>
         


                {!performed && (
                <Box 
                    sx={classes.addExerciceButton}
                >
                    <SecondaryButton 
                        onClick={()=> setOpenList(true)} 
                        sx={{
                            textTransform: "none", 
                            borderRadius: "24px !important",
                            marginBottom: {
                                xs: 2,
                                sm: 0
                            }
                        }} 
                        disableElevation={true}
                        startIcon={<Add/>}
                    >
                        Ajouter un exercice
                    </SecondaryButton>
                    
                 </Box>
                )}
            </Box>


            {/********** LISTE DES EXOS ******* */}

            <DialogExercicesList 
                open={openList} 
                onClose={onCloseExercicesList} 
                blockId={blockId}
                exerciceId={exerciceToSwap?.exerciceId}
                groupId={exerciceToSwap?.groupId}
                swap={Boolean(exerciceToSwap)}
            />

            {/********** Dialog de suppression d'un bloc ******* */}

            <DialogBlockDelete
                open={openDelete}
                onClose={()=> setOpenDelete(false)}
                blockId={blockId}
                blockName={name}
            />


            <DialogBlockTracking
                open={openBlockTracking}
                onClose={()=> setOpenBlockTracking(false)}
                blockIndex={blockIndex}
                blockId={blockId}
                currentTrack={track}
            />

        </Box>
    )
}





export default WorkoutBlock

