import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "app/store"
import { getExerciceDetails } from "function/getExerciceDetails"
import { WorkoutBlock, WorkoutExercice } from "interfaces/Workout"


/**  
    Vérifie sir l'exercice est le dernier de son groupe
*/ 
export const selectIsLastExerciceInGroup  = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const blockIndex = workoutBuilder.blocks.findIndex((block) => block.blockId === blockId)
        const groupIndex =  workoutBuilder.blocks[blockIndex].content.findIndex((elem)=> elem.groupId === groupId)
        const group =  workoutBuilder.blocks[blockIndex].content[groupIndex]
        const exerciceIndex =  group.exercices.findIndex((elem)=> elem.exerciceId === exerciceId)
        if(exerciceIndex === group.exercices.length -1){
            return true
        } 
        return false
    }
)

/**  
    Séelectionne le type de bloc
*/ 
export const selectBlockType = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,blockId) => blockId,
    ],
    (workoutBuilder, blockId) => {
        return workoutBuilder.blocks.find((elem)=> elem.blockId === blockId).blockType

    }
)


/**  
    Vérifie si un exercice est dans un superset
*/ 
export const selectExerciceIsFromSuperset = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_, __, blockId) => blockId
    ],
    (workoutBuilder, groupId, blockId) => {
        const blockIndex = workoutBuilder.blocks.findIndex((block) => block.blockId === blockId)
        const groupIndex =  workoutBuilder.blocks[blockIndex].content.findIndex((elem)=> elem.groupId === groupId)
        const group =  workoutBuilder.blocks[blockIndex].content[groupIndex]
        return group.exercices.length > 1

       
    }
)


/**  
    Vérifie si un groupe est le dernier du bloc
*/ 
export const selectGroupIsLastOfBloc = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_, __, blockId) => blockId
    ],
    (workoutBuilder, groupId, blockId) => {
        const blockIndex = workoutBuilder.blocks.findIndex((block) => block.blockId === blockId)
        const block = workoutBuilder.blocks[blockIndex]
        const groupIndex =  workoutBuilder.blocks[blockIndex].content.findIndex((elem)=> elem.groupId === groupId)
        return groupIndex === block.content.length - 1

       
    }
)

/**  
    Vérifie si un exercice a un temps de repos défini
*/ 
export const selectExerciceHasRest = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.restBetweenSets
    }
)

/**  
    Retourne le nom d'un exercice
*/ 
export const selectExerciceName = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.exercice.name
    }
)


export const selectExerciceType = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.exercice.type._id
    }
)


export const selectExerciceNote = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.note
    }
)


export const selectExerciceMetrics = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId)
        var listOfMetrics = [...workoutExercice.metrics]
        
        return listOfMetrics

    }
)


export const selectExerciceCircuitValues = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId)
        return workoutExercice.circuit

    }
)


export const selectExerciceImage = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.exercice.image
    }
)


export const selectExerciceSets = createSelector(
    [
        (state:RootState)=> state.workouts.builder,
        (_,groupId) => groupId,
        (_,__,exerciceId) => exerciceId,
        (_, __, ___, blockId) => blockId
    ],
    (workoutBuilder, groupId, exerciceId, blockId) => {
        const workoutExercice:WorkoutExercice = getExerciceDetails(workoutBuilder, blockId, groupId, exerciceId,)
        return workoutExercice.sets
    }
)


export const selectBlock = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, blockId) => {
        const block:WorkoutBlock = builder.blocks.find((elem:WorkoutBlock)=> elem.blockId === blockId)
        return block
    }
)


export const selectBlockCircuitSettings = createSelector(
    [
        (state:RootState) => state.workouts.builder,
        (_, blockId:string) => blockId
    ],
    (builder, blockId) => {
        const block:WorkoutBlock = builder.blocks.find((elem:WorkoutBlock)=> elem.blockId === blockId)
        return block.circuit
    }
)




